import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import axios from "axios";
import apiConfig from "../../../services/apiConfig";
import * as BsIcons from "react-icons/bs";
import { ListGroup } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TabContent from "react-bootstrap/TabContent";
import moment from "moment";
import { NavLink, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import Imagen from "./Imagen";
import Update from "./Update/Update";

const HistorialCliente = () => {
  let { id } = useParams();
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(id), "clave_secreta");
  const info = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  const [cliente, setCliente] = useState(info);
  const [historial, setHistorial] = useState("");


  const colorMap = {
    1: "#ffba57", // "solicitado"
    2: "#39afd1", // "reservado"
    3: "#3b82f6", // "confirmado"
    4: "#fa5c7c", // "suspendido"
    5: "#9e9e9e", // "no asistió"
    6: "#0acf97", // "finalizado"
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePassword1 = () => {
    if (passwordType === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const [actualizado, setActualizado] = useState(false);

  const pass = () => {
    if (password.length > 0 && password === password1) {
      let userInfo = {
        userId: info.id_cliente,
        password: password,
        password1: password1,
      };

      axios
        .post(apiConfig + `customer/list/changePassword.php`, userInfo)
        .then((res) => {
          let { data } = res;
          if (data.success && data.message) {
            if (data.success === 1) {
              setActualizado(true);
            }
          }
        })
        .catch(function (res) {});
    }
  };
  function getData(i) {
    setCliente(i);
  }
  useEffect(() => {
	axios
	.get(apiConfig + 'turnos/list/individual.php?id=' + Number(info.id_cliente))
	.then((getData) => {
	  setHistorial(getData.data);
	  
	});
}, [cliente.id_cliente]);

  return (
    <Container>
      <div className="text-start">
        <NavLink exact to={"/config/customer"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#736CED" }}>
        <div className="card-body">
          <div className="text-center text-white">Información Cliente</div>
        </div>
      </div>
      <Row className="pt-3">
        <Col sm={12} md={12}>
          <Card>
            <div className="container text-center pt-5">
              <Imagen cliente={cliente} />
              <div className="pt-3">
                <strong>{cliente.seudonimo}</strong>
              </div>
              <Row>
                <Col>
                  <div>
                    <strong>Reservas</strong>
                    <br />
                    {historial ? historial.length : "0"}
                  </div>
                </Col>
                <Col style={{ borderLeft: "solid 1px #ededed" }}>
                  <div>
                    <strong>Última</strong>
                    <br />
                    {historial.length > 0
                      ? moment(historial[0].fecha).format("DD-MM-YYYY")
                      : "-"}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <br />
          <Card>
            <Card.Body>
              <Tabs
                defaultActiveKey="Perfil"
                id="fill-tab-example"
                className="mb-3"
                fill
              >
                <Tab eventKey="Perfil" title="Perfil">
                  <TabContent>
                    <div className="text-start">
                      <ul>
                        <li>
                          <strong>Nombre</strong>
                          <br />
                          {cliente.nombre},{cliente.apellido}
                        </li>
                        <li>
                          <strong>Teléfono</strong>
                          <br />
                          {cliente.telefono
                            ? cliente.telefono
                            : "No registrado"}
                        </li>
                        <li>
                          <strong>Correo electrónico</strong>
                          <br />
                          {cliente.email ? cliente.email : "No registrado"}
                        </li>
                      </ul>
                    </div>
                  </TabContent>
                </Tab>
                <Tab eventKey="Editar" title="Editar">
                  <TabContent>
                    <Row>
                      <div className="col-md-6 col-sm-12 text-start">
                        <h4>Editar perfil</h4> <br />
                        <Update data={cliente} getData={getData} />
                      </div>
                      <div className="col-md-6 col-sm-12 text-start">
                        <h4>Cambiar contraseña</h4> <br />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                          className="mb-2"
                        >
                          <FloatingLabel
                            controlId="floatingTotal"
                            label="Contraseña"
                            className="col-10"
                          >
                            <Form.Control
                              type={passwordType}
                              name="password"
                              placeholder=""
                              onChange={(e) => setPassword(e.target.value)}
                              minLength={6}
                              maxLength={6}
                            />
                          </FloatingLabel>
                          <div onClick={togglePassword}>
                            {passwordType === "password" ? (
                              <small>Mostrar</small>
                            ) : (
                              <small>Ocultar</small>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                          className="mb-3 "
                        >
                          <FloatingLabel
                            controlId="floatingTotal1"
                            label="Contraseña"
                            className="col-10"
                          >
                            <Form.Control
                              type={passwordType1}
                              name="password"
                              placeholder=""
                              onChange={(e) => setPassword1(e.target.value)}
                              minLength={6}
                              maxLength={6}
                            />
                          </FloatingLabel>
                          <div onClick={togglePassword1}>
                            {passwordType1 === "password" ? (
                              <small>Mostrar</small>
                            ) : (
                              <small>Ocultar</small>
                            )}
                          </div>
                        </div>
                        {password !== password1 ? (
                          <p>La contraseña no coinciden</p>
                        ) : (
                          <p> </p>
                        )}
                        {actualizado && (
                          <p className="text-success">Actualizado</p>
                        )}
                        <div className=" text-start">
                          <button
                            className="btn app-button  text-white shadow"
                            onClick={pass}
                          >
                            Cambiar
                          </button>
                        </div>
                      </div>
                    </Row>
                  </TabContent>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
          <br />

          <Card>
            <Card.Body className="text-start">
              <div>
                <h3>Historial</h3>
              </div>
              <div className="profile-scrollable">
                <ListGroup>
                  {historial ? (
                    historial.map((data, index) => (
                      <ListGroup.Item key={index}>
                        <Row>
                          <Col sm={12} md={6}>
                            <div>
                              <strong> {data.tratamiento}</strong>
                            </div>
                            <div>
                              {moment(data.fecha).format("DD-MM-YYYY")}{" "}
                              {data.hora}
                            </div>
                            <div>
                              <strong>Estado de pago:</strong>{" "}
                              {data.estado_pago === "Pendiente" && (
                                <span style={{ color: "red" }}>
                                  {data.estado_pago}
                                </span>
                              )}
                              {data.estado_pago === "Pago parcial" && (
                                <span style={{ color: "#ffba57" }}>
                                  {data.estado_pago}
                                </span>
                              )}
                              {data.estado_pago === "Pagado" && (
                                <span style={{ color: "#0acf97" }}>
                                  {data.estado_pago}
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col className="text-start" sm={12} md={6}>
                            <div>
                              <strong>Importe:</strong>{" "}
                              {Number(data.precio) > 0
                                ? Number(data.precio).toLocaleString("es-AR", {
                                    style: "currency",
                                    currency: "ARS",
                                    minimumFractionDigits: 2,
                                  })
                                : "$ 0,00"}
                            </div>
                            <div>
                              <strong>Observaciónes:</strong>
                              <br /> {data.observaciones}
                            </div>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))
                  ) : (
                    <div>Sin registros</div>
                  )}
                </ListGroup>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6}></Col>
      </Row>
    </Container>
  );
};

export default HistorialCliente;
