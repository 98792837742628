import {useState,useEffect} from 'react';
import * as BsIcons from 'react-icons/bs';
import { NavLink  } from 'react-router-dom';
import axios from 'axios';
import apiConfig from "../../../services/apiConfig";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import useAuth from "../../../auth/useAuth";

function BoxList(){
	const auth = useAuth();
    const user = JSON.parse(localStorage.getItem('user'));
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [show1, setShow1] = useState(false);
	const handleClose1 = () => setShow1(false);
	const handleShow1 = () => setShow1(true);
	const [data, setData] = useState([]);
	const [editar,setEditar] = useState([]);
	const [registro,setRegistro]= useState({
		nombre: '',
		descripcion: '',
		estado: 1,
		id_business_office: user.id_business_office,
	 });
	

	useEffect(() => {
		axios.get(apiConfig+'box/list/?id='+Number(user.id_business_office))
		.then((getData) => {
			setData(getData.data);                
		});
	}, [user.id_business_office]);

	function getData(){
		axios.get(apiConfig+'box/list/?id='+Number(user.id_business_office))
		.then((getData) => {
			setData(getData.data);                
		});
	};

	function handleSubmit(e){
		e.preventDefault();
		axios.post(apiConfig+`box/add/index.php`,registro)
		   .then((res) => {
			  let {data} = res;            
			  if(Number(data.success) === 1){
				getData();
				setShow(false)
			  }		 
		   })
		  .catch(function (res) {			  
		});	  
	}
	function handleSubmit1(e){
		e.preventDefault();
		axios.post(apiConfig+`box/put/editar.php`,editar)
		   .then((res) => {			
			  let {data} = res;            
			  if(Number(data.success) === 1){
				getData();
				setShow1(false)
			  }		 
		   })
		  .catch(function (res) {
			console.log(res)			  
		});	  
	}
	
	const handleChange = (e) => {
		setRegistro({ ...registro, [e.target.name]: e.target.value });
	  };

	  const handleChange1 = (e) => {
		setEditar({ ...editar, [e.target.name]: e.target.value });
	  };

	  function onErase (id){       
        axios.get(apiConfig+`box/delete/delete.php?id=${id}`)
        .then(() => {
            getData();
        })
    }

    function onDelete(id){
        axios.get(apiConfig+`box/delete/index.php?id=${id}`)
        .then(() => {
            getData();
        })
    }

    function onActive(id) {
        axios.get(apiConfig+`box/delete/active.php?id=${id}`)
        .then(() => {
            getData();
        })
    }

	return (
		<div className='container' style={{paddingTop:'70px'}}>
			<div className="row">
				<div className="col-1">
					<NavLink exact to={'/config/'} >
						<h1><BsIcons.BsArrowLeftShort className="app-color" /></h1>
					</NavLink>	
				</div>
				<div className="col">
					<h1 className='text-muted pt-3'>Box - Consultorio</h1>
				</div>
			</div>
			<div className='text-end  mb-5'>
			<h1>
            <BsIcons.BsPlusCircleFill onClick={handleShow} style={{ color: "#736CED" }}/>
        	 </h1>
			</div>
			<Modal show={show} size="lg" onHide={handleClose}>
				<Modal.Header closeButton>				
				</Modal.Header>
				<Modal.Body>
					<div className='pt-2 mb-5'>
						<h1>Agregar Box - Consultorio</h1>
					</div>
					<Row>
						<Col md={6}>
							<FloatingLabel controlId="floatingCodigo" label="Numero *" className="mb-3">
								<Form.Control type="number" name="nombre" placeholder="Numero" onChange={handleChange} required/>
							</FloatingLabel>
						</Col>
						<Col md={6}>
							<FloatingLabel	controlId="floatingDescripcion" label="Descripcion *"	className="mb-3">
								<Form.Control type="text" name="descripcion" placeholder="Descripción" onChange={handleChange} required/>
							</FloatingLabel>
						</Col>
					</Row>
					<div className="d-grid gap-2 pt-5">
						<button onClick={handleSubmit} className="btn app-button  text-white shadow"  >Guardar</button>
					</div>
				</Modal.Body>
			</Modal>
			<div className='card shadow mb-3' style={{ background: "#736CED" }}>
				<div className='card-body'>
					<div className='row text-white'>
						<div className='col-1 '>Estado</div>
						<div className='col-3 '>Numero</div>            
						<div className='col-6'>Descripción</div>      
						<div className="col-2 ">
						Accion
						</div>
					</div>
				</div>  
			</div>
			{data && data.map((datos,index)=>(
			<div key={index}>
			{Number(datos.estado) === 1 ?
			<>
			<div className='card shadow mb-3 '>
				<div className='card-body'>
				<div className='row '>
						<div className='col-1 align-self-center h3'><BsIcons.BsFillRecordFill style={{color:'#30ff24'}}/></div>
						<div className='col-3 align-self-center'>{datos.nombre}</div>            
						<div className='col-6 align-self-center'>{datos.descripcion}</div>      
						<div className="col-2 ">
							<div className='row'>
							{Number(datos.estado) === 1 ?
							<div className="col-6 text-center align-self-center"  onClick={() =>
								onDelete(datos.id)}>
								<h3>
									<BsIcons.BsToggleOn />
								</h3>
							</div>
							:
							<div className="col-6 text-center align-self-center"  onClick={() =>
								onActive(datos.id)}>
								<h3>
									<BsIcons.BsToggleOff />
								</h3>
							</div>
							}                  
								<div className='col-6 text-center align-self-center'>
								<div  data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" onClick={()=>
									setEditar(datos)}>
									<div className="">
										<h3>
											<BsIcons.BsThreeDotsVertical onClick={handleShow1} />
										</h3>
									</div>
								</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={show1} size="lg" onHide={handleClose1}>
			<Modal.Header closeButton>				
			</Modal.Header>
			<Modal.Body>
				<div className='pt-2 mb-5'>
					<h1>Editar Box - Consultorio</h1>
				</div>
				<Row>
					<Col md={6}>
						<FloatingLabel controlId="floatingCodigo" label="Numero *" className="mb-3">
							<Form.Control type="number" name="nombre" value={editar && editar.nombre} placeholder="Numero" onChange={handleChange1} />
						</FloatingLabel>
					</Col>
					<Col md={6}>
						<FloatingLabel	controlId="floatingDescripcion" label="Descripcion *"	className="mb-3">
							<Form.Control type="text" name="descripcion" value={editar && editar.descripcion} placeholder="Descripción" onChange={handleChange1} />
						</FloatingLabel>
					</Col>
				</Row>
				<div className="d-grid gap-2 pt-5">
					<button onClick={handleSubmit1} className="btn app-button  text-white shadow"  >Guardar</button>
				</div>
			</Modal.Body>
		</Modal>
		</>
			:
			<div className='card shadow mb-3 '>
				<div className='card-body'>
				<div className='row text-muted'>
						<div className='col-1 align-self-center h3'><BsIcons.BsFillRecordFill style={{color:'#f80f00'}}/></div>
						<div className='col-3 align-self-center'>{datos.nombre}</div>            
						<div className='col-6 align-self-center'>{datos.descripcion}</div>      
						<div className="col-2 align-self-center">
							<div className='row'>
							{Number(datos.estado) === 1 ?
							<div className="col-6 text-center align-self-center"  onClick={() =>
								onDelete(datos.id)}>
								<h3>
									<BsIcons.BsToggleOn />
								</h3>
							</div>
							:
							<div className="col-6 text-center align-self-center"  onClick={() =>
								onActive(datos.id)}>
								<h3>
									<BsIcons.BsToggleOff />
								</h3>
							</div>
							}                  
								<div className='col-6 text-center align-self-center'>
									<div  data-bs-toggle="tooltip" data-bs-placement="top" title="Eliminar" onClick={() =>
										onErase(datos.id)}>
										<h3>
											<BsIcons.BsTrash />
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			}
			</div>				
			))}
		</div>
	)
};

export default BoxList;
