import { useState } from 'react';
import * as BsIcons from "react-icons/bs";
import Row from "react-bootstrap/Row";
import './ClienteLista.css';

const ClienteLista = ({ clientes, handleChangeClientes  }) => {
  const [filtro, setFiltro] = useState('');

  return (
    <>
      <div className="pt-2 mb-3">
        <div className="input-group ">
          <input
            placeholder="Buscar"
            className="form-control form-control-sm"
            type="text"
            onChange={(e) => setFiltro(e.target.value)}
          />
          <span className="input-group-text" id="basic-addon2">
            <BsIcons.BsSearch />
          </span>
        </div>
      </div>
      <Row>
        {clientes
          ? clientes
              .filter(
                (cliente) =>
                  
                  cliente.nombre.toLocaleLowerCase().includes(filtro.toLocaleLowerCase()) ||
                  cliente.apellido.toLocaleLowerCase().includes(filtro.toLocaleLowerCase())
              )
              .sort((a, b) => a.nombre.localeCompare(b.nombre))
              .map((data, index) => (
                <div
                  className="col-12 text-start cliente-lista"
                  key={index}
                  onClick={()=>handleChangeClientes(data)}
                >
                  {data.seudonimo}
                </div>
              ))
          : (
            <div className="col-12 text-start cliente-lista">
              No hay clientes registrados
            </div>
          )}
      </Row>
    </>
  );
};

export default ClienteLista;
