import React from "react";

const Terminos = () => {
  return (
    <div className=" container text-start">
      <div>
        <div>          
          <p>Actualizado al 01 de junio de 2023</p>
        </div>
      </div>
      <div>
        <div>
          <br />
        </div>
        <div>
          <div>
            <div>
              <h1>
                <strong>1. General</strong>
              </h1>
              <ol>
                <li>
                  Los&nbsp;<strong>T&eacute;rminos y Condiciones</strong>{" "}
                  establecidos por Cronogram App, aplican sobre la
                  provisi&oacute;n de su plataforma de agendamiento online
                  Cronogram, as&iacute; como sobre las Cuentas de Clientes y
                  Usuarios, productos, servicios y tecnolog&iacute;a en general;
                  asociados a dicha plataforma.
                </li>
                <li>
                  Los consumidores de lo servicios prestados por Cronogram son
                  identificados como aquellas personas naturales o con calidad
                  jur&iacute;dica que contraten el servicio (en adelante:
                  &ldquo;Cliente(s)&rdquo;), es decir, mantengan una
                  relaci&oacute;n contractual con Cronogram App; as&iacute; como
                  tambi&eacute;n aquellas personas que no mantienen
                  relaci&oacute;n contractual comercial con la
                  compa&ntilde;&iacute;a, pero son usuarios finales de la
                  plataforma (en adelante: &ldquo;Usuario(s)&rdquo;).
                </li>
                <li>
                  Al aceptar los&nbsp;
                  <strong>T&eacute;rminos y Condiciones</strong> aqu&iacute;
                  fijados, tanto el Cliente como el Usuario est&aacute;n
                  suscribiendo un contrato con Cronogram App, sociedad
                  propietaria de Cronogram.
                </li>
                <li>
                  En adelante, Cronogram App y su plataforma de agendamiento,
                  ser&aacute;n denominadas sin distinci&oacute;n como Cronogram.
                </li>
                <li>
                  Cronogram es una plataforma web de agendamiento online,
                  contenida en el sitio&nbsp;
                  <a href="https://cronogram.com.ar/">
                    <strong>https://cronogram.com.ar</strong>
                  </a>
                  , que permite reservar servicios prestados por terceros
                  (Clientes).
                </li>
                <li>
                  Bajo ninguna circunstancia Cronogram es la encargada de
                  prestar el servicio ofrecido por los terceros (Clientes).
                  Tampoco se har&aacute; cargo de la calidad, puntualidad u
                  otros, asociados con el servicio prestado por el empresario o
                  empresa.
                </li>
                <li>
                  Al utilizar cualquier servicio, actual o futuro de Cronogram,
                  se estar&aacute; sujeto a los lineamientos y condiciones
                  aplicables.
                </li>
                <li>
                  El desconocimiento del contenido de los&nbsp;
                  <strong>T&eacute;rminos y Condiciones</strong> no justifica el
                  incumplimiento de los mismos.
                </li>
                <li>
                  <strong>T&eacute;rminos o Condiciones</strong>, que difieran a
                  los aqu&iacute; presentes o sean contrarios a &eacute;stos, no
                  deben ser aceptados por los clientes a menos que Cronogram
                  afirme, expresamente y por medio escrito, su
                  validaci&oacute;n.
                </li>
                <li>
                  Sin perjuicio de lo anterior, Cronogram se reserva la facultad
                  de modificar o actualizar las condiciones y lineamientos
                  establecidos en el presente documento; en cuyo caso
                  avisar&aacute; oportunamente, a las Partes interesadas, los
                  cambios efectuados.
                </li>
                <li>
                  Al hacer uso de nuestros servicios, tanto el Cliente como el
                  Usuario, tambi&eacute;n aceptan y expresan su conformidad con
                  nuestra&nbsp;
                  <strong>
                    <a href="https://panel.cronogram.com.ar/documents/privacy-policy">
                      Pol&iacute;tica de Privacidad
                    </a>
                  </strong>
                  , que comprende la forma en que recabamos, utilizamos,
                  compartimos y almacenamos su informaci&oacute;n personal.
                </li>
              </ol>
              <h1>
                <strong>2. Definiciones</strong>
              </h1>
              <p>
                <strong>
                  <em>Agenda:</em>
                </strong>{" "}
                herramienta de la plataforma que permite organizar y planificar
                los horarios de los distintos Prestadors que posee el Cliente.
              </p>
              <p>
                <strong>
                  <em>Cliente:</em>
                </strong>{" "}
                persona natural o con calidad jur&iacute;dica, que contrata la
                suscripci&oacute;n mensual de Cronogram, para hacer uso de la
                licencia y herramientas que &eacute;ste provee.
              </p>
              <p>
                <strong>
                  <em>Cuenta:</em>
                </strong>{" "}
                credencial de usuario necesaria para acceder a la plataforma.
                Tiene vinculada una contrase&ntilde;a. Es &uacute;nica.
              </p>
              <p>
                <strong>
                  <em>Cupo:</em>
                </strong>{" "}
                n&uacute;mero de personas que puede hacer uso de un servicio al
                mismo tiempo.
              </p>
              <p>
                <strong>
                  <em>Partes:</em>
                </strong>{" "}
                son quienes intervienen en el presente documento, que los
                vincula contractualmente; a saber: Cliente, Usuario y Cronogram.
              </p>
              <p>
                <strong>
                  <em>Prestador:</em>
                </strong>{" "}
                profesionales o bienes que sean dispuestos por el Cliente, para
                prestar sus servicios. Cada Prestador cuenta con una Agenda
                asociada.
              </p>
              <p>
                <strong>
                  <em>Usuario:</em>
                </strong>{" "}
                es quien interact&uacute;a y realiza reservas, a trav&eacute;s
                de la plataforma del Cliente (provista por Cronogram), para
                acceder a los servicios prestados por &eacute;ste.
              </p>
              <h1>
                <strong>3. Contrato</strong>
              </h1>
              <p>
                Los v&iacute;nculos contractuales que se generan con empresas o
                emprendedores, como resultado de la suscripci&oacute;n a nuestro
                servicio de agendamiento online Cronogram, se rigen por las
                condiciones aqu&iacute; establecidas. Desde el momento en que
                &eacute;stos obtienen los derechos de uso o licencia de la
                plataforma, mediante la contrataci&oacute;n del servicio,
                adquieren la calidad de Cliente y aceptan todas las
                cl&aacute;usulas aqu&iacute; contenidas. Dichas cl&aacute;usulas
                tienen efecto respecto del uso de Cronogram, as&iacute; como
                tambi&eacute;n de las Cuentas creadas y vinculadas por parte del
                Cliente y/o sus empleados.
              </p>
              <p>
                Cronogram no reconocer&aacute; ni aceptar&aacute;
                cl&aacute;usula alguna por parte del Cliente, si &eacute;sta no
                se encuentra estipulada expresamente en este documento.
                Cualquier modificaci&oacute;n o actualizaci&oacute;n que se
                realice en el presente registro, ser&aacute; informada con
                anticipaci&oacute;n a las Partes intervinientes.
              </p>
              <p>
                El v&iacute;nculo contractual, amparado por la
                suscripci&oacute;n realizada, tendr&aacute; una vigencia mensual
                y ser&aacute; renovable autom&aacute;ticamente. Para poder
                suscribirse, el Cliente deber&aacute; registrar la
                informaci&oacute;n de su tarjeta de cr&eacute;dito,
                d&eacute;bito o prepago y asociarla como m&eacute;todo de pago.
                Si el cliente quisiera dar de baja los servicios contratados,
                deber&aacute; eliminar, desde su Cuenta, la tarjeta asociada.
                Dicha cancelaci&oacute;n podr&aacute; ser efectuada en el
                momento en que el Cliente estime conveniente, sin embargo el
                cobro generado ser&aacute; por la totalidad del servicio
                contratado durante el mes en que se solicit&oacute; la baja.
              </p>
              <p>
                Las tarifas o cobros, por los que deber&aacute; responder el
                Cliente, est&aacute;n asociados al plan contratado (
                <a href="https://cronogram.com.ar/#plans">
                  <strong>saber m&aacute;s sobre nuestros planes</strong>
                </a>
                ). Se a&ntilde;adir&aacute;n cobros extraordinarios, que
                ir&aacute;n incluidos en el total facturado, dependiendo del
                n&uacute;mero de Agendas adicionales contratadas y vinculadas al
                plan seleccionado. Tambi&eacute;n se consideran cobros
                extraordinarios los Cupos adicionales que sean contratados para
                cada servicio. Tanto las Agendas como los Cupos pueden ser
                contratados, en cualquier momento y directamente desde la
                plataforma, por el Cliente. Las tarifas podr&aacute;n ser
                modificadas, en cuyo caso Cronogram se compromete a informar,
                con 30 d&iacute;as de anticipaci&oacute;n, el alza o
                disminuci&oacute;n de &eacute;stas a los clientes que se vean
                afectados.
              </p>
              <p>
                El Cliente podr&aacute; cambiarse de plan cuando lo requiera.
                Para ello, deber&aacute; seleccionar el nuevo plan que desea
                contratar directamente en la p&aacute;gina web&nbsp;
                <a href="https://cronogram.com.ar/">
                  <strong>https://cronogram.com.ar</strong>
                </a>
                . Si el Cliente solicita un plan con mayor costo asociado, este
                nuevo plan ser&aacute; activado inmediatamente y se
                cobrar&aacute; un costo proporcional (relacionado a la cantidad
                de d&iacute;as que resten del per&iacute;odo mensual de
                facturaci&oacute;n). En caso contrario, si el nuevo plan
                contratado es de menor precio, &eacute;ste comenzar&aacute; a
                regir desde el pr&oacute;ximo per&iacute;odo mensual de
                facturaci&oacute;n; manteniendo el plan, inicialmente
                contratado, por la vigencia pactada en primera instancia.
              </p>
              <p>
                Por otra parte, el Usuario, acepta suscribir un contrato
                vinculante, desde el punto de vista jur&iacute;dico, al momento
                de registrarse o asociar alg&uacute;n tipo de Cuenta que le
                permita acceder o utilizar los servicios prestados por la
                plataforma. Si el Usuario no est&aacute; conforme con los&nbsp;
                <strong>T&eacute;rminos y Condiciones</strong> aqu&iacute;
                dispuestos, no debe aceptarlos. El no aceptar los&nbsp;
                <strong>T&eacute;rminos y Condiciones</strong> de Cronogram
                imposibilita la creaci&oacute;n de una Cuenta y, por lo tanto,
                el acceso a la plataforma. Si, por el contrario, el Usuario
                acepta los&nbsp;<strong>T&eacute;rminos y Condiciones</strong>,
                se da por hecho que los ha le&iacute;do y los comprende;
                asumiendo las responsabilidades y obligaciones que eso conlleva.
              </p>
              <p>
                En caso de querer finalizar el contrato aqu&iacute; establecido,
                el Usuario deber&aacute; cerrar su Cuenta y no acceder a
                nuestros servicios ni usarlos. Dicha acci&oacute;n puede ser
                realizada en el momento en que &eacute;ste estime conveniente.
              </p>
              <p>
                Este contrato tiene aplicaci&oacute;n sobre&nbsp;
                <a href="https://cronogram.com.ar/">
                  <strong>https://cronogram.com.ar</strong>
                </a>
                , las aplicaciones de la marca de Cronogram y otros sitios
                relacionados con Cronogram. &Eacute;ste tambi&eacute;n tiene
                efecto sobre aplicaciones, comunicaciones y otros servicios que
                declaran que se ofrecen en virtud de &eacute;l.
              </p>
              <h1>
                <strong>4. Obligaciones</strong>
              </h1>
              <h2>
                <strong>4.1. Pago</strong>
              </h2>
              <p>
                El Cliente se compromete a realizar de manera oportuna el pago
                de la suscripci&oacute;n contratada, en caso de no liberarse de
                manera directa desde la tarjeta de cr&eacute;dito, ya sea por
                falta de fondos, bloqueo de la misma u otros. Es responsabilidad
                del Cliente verificar con su banco el motivo o la causa de dicha
                situaci&oacute;n. El Cliente tambi&eacute;n asume que nuestros
                servicios pueden estar sujetos a tasas o impuestos adicionales.
              </p>
              <p>
                El no pago de la suscripci&oacute;n, de acuerdo a los plazos
                establecidos, facultar&aacute; a Cronogram para poner fin al
                servicio que presta y, por ende, a la relaci&oacute;n
                contractual de manera unilateral e inmediata.
              </p>
              <p>
                Independiente del plan contratado, el Cliente acepta abonar las
                tasas e impuestos aplicables. El incumplimiento del pago de
                dichas tasas e impuestos faculta a Cronogram para cancelar la
                suscripci&oacute;n.
              </p>
              <p>El Cliente tambi&eacute;n acepta lo siguiente:</p>
              <ul>
                <li>
                  Que su compra puede ser sujeto de incrementos o disminuciones
                  en el precio, producto de comisiones o diferencias cambiarias,
                  dependiendo de su ubicaci&oacute;n.
                </li>
                <li>
                  Que su forma de pago ser&aacute; almacenada por un procesador
                  de pagos externo con est&aacute;ndares mundiales de seguridad.
                  Cronogram podr&aacute; seguir utilizando esa forma de pago
                  para fines de facturaci&oacute;n, con el objetivo de asegurar
                  la continuidad de los servicios y utilizarla para pagar otros
                  servicios que pueda adquirir.
                </li>
                <li>
                  Que al contratar un plan se cargar&aacute;n los cobros por
                  adelantado (que incluyen tasas e impuestos aplicables) en el
                  medio de pago inscrito. El cobro se har&aacute; efectivo de
                  forma inmediata, una vez haya sido contratado el servicio. Los
                  cobros de las suscripciones mensuales posteriores se
                  har&aacute;n efectivos los d&iacute;as 1 de cada mes.
                </li>
                <li>
                  Que las tasas e impuestos se calcular&aacute;n dependiendo de
                  la informaci&oacute;n de facturaci&oacute;n proporcionada por
                  &eacute;l.
                </li>
              </ul>
              <p>
                En relaci&oacute;n a la opci&oacute;n &quot;Pausar la
                suscripci&oacute;n&quot;, presente en la secci&oacute;n
                Facturaci&oacute;n del Panel de Administraci&oacute;n, el
                Cliente deber&aacute; solicitar dicha pausa durante el mes
                anterior. Por ejemplo, si un cliente desea pausar su
                suscripci&oacute;n durante el mes de marzo, deber&aacute;
                solicitar la pausa en febrero. Este proceso lo puede realizar
                por su propia cuenta. No se efectuar&aacute; el cobro normal de
                la suscripci&oacute;n mensual, pero s&iacute; ser&aacute;
                aplicable un cobro fijo mensual por mantener los datos y la
                informaci&oacute;n contenida en el perfil. Para mayor
                informaci&oacute;n, en relaci&oacute;n al cobro mencionado,
                acceder a:&nbsp;
                <a href="https://cronogram.com.ar/#plans">
                  <strong>saber m&aacute;s sobre nuestros planes</strong>
                </a>
                .
              </p>
              <p>
                Los reembolsos que deban ser proporcionados por Cronogram, como
                consecuencia de fallas o interrupciones que atenten contra el
                correcto funcionamiento de la plataforma, se regir&aacute;n por
                nuestro&nbsp;
                <strong>
                  <a href="https://panel.cronogram.com.ar/documents/service-level-agreement">
                    SLA
                  </a>
                </strong>
                .
              </p>
              <p>
                El Cliente podr&aacute; solicitar la informaci&oacute;n de pago
                que estime conveniente al correo&nbsp;
                <a href="mailto:soporte@cronogram.com.ar">
                  <strong>soporte@cronogram.com.ar</strong>
                </a>
                .
              </p>
              <h2>
                <strong>4.2. Datos de Facturaci&oacute;n</strong>
              </h2>
              <p>
                El cliente deber&aacute; proporcionar los datos necesarios para
                generar los documentos tributarios relacionados al pago de la
                suscripci&oacute;n mensual por el uso de la plataforma. Dichos
                datos son resguardados de acuerdo a lo dispuesto en
                nuestra&nbsp;
                <strong>
                  <a href="https://panel.cronogram.com.ar/documents/privacy-policy">
                    Pol&iacute;tica de Privacidad
                  </a>
                </strong>
                .
              </p>
              <h2>
                <strong>4.3. Cuentas</strong>
              </h2>
              <p>
                Tanto los Clientes como los Usuarios aceptan utilizar una
                contrase&ntilde;a segura y mantener la confidencialidad de la
                misma. Por otra parte, se comprometen a respetar la
                legislaci&oacute;n vigente y aplicable, as&iacute; como
                tambi&eacute;n los permisos y restricciones estipulados en este
                documento. La confidencialidad de la informaci&oacute;n relativa
                al acceso a las Cuentas vinculadas a la plataforma, tanto por
                parte de los Clientes como de los Usuarios, debe ser resguardada
                por ellos.
              </p>
              <p>
                Es responsabilidad de los Clientes y Usuarios lo que suceda en
                su Cuenta, salvo que &eacute;sta haya sido cerrada o se reporte
                el uso fraudulento o malicioso de ella.
              </p>
              <p>
                En el caso de los Clientes; que podr&aacute;n contar con una o
                m&aacute;s Cuentas, as&iacute; como tambi&eacute;n Agendas
                (pudiendo ser ambas administradas por empleados), es parte de su
                compromiso asegurar el correcto uso y la confidencialidad de las
                mismas. Adem&aacute;s, son ellos quienes deben asegurarse que la
                informaci&oacute;n relativa al acceso sea solo compartida con
                personas que han autorizado. Por otra parte, ser&aacute; el
                Cliente quien asuma toda la responsabilidad por las acciones
                ejecutadas por aquellas personas, autorizadas por &eacute;l,
                para hacer uso de la(s) Cuentas o la(s) Agendas. En caso de
                transgredir alguna de nuestras restricciones, ser&aacute;
                tambi&eacute;n el Cliente quien deba asumir la responsabilidad
                exigida por la legislaci&oacute;n vigente.
              </p>
              <h2>
                <strong>4.4. Comunicados e informaci&oacute;n</strong>
              </h2>
              <p>
                Cliente y Usuario aceptan recibir comunicados e
                informaci&oacute;n a trav&eacute;s de nuestro sitio web y
                plataforma, al igual que por medio de los canales de contacto
                que nos han proporcionado. Es responsabilidad de ambos mantener
                actualizados estos datos, ya que ser&aacute;n las v&iacute;as de
                comunicaci&oacute;n formal para difundir informaci&oacute;n que
                puede resultar importante o confidencial.
              </p>
              <h2>
                <strong>4.5. Requisitos para hacer uso de la plataforma</strong>
              </h2>
              <p>
                Para usar los servicios de Cronogram, tanto el Cliente como el
                Usuario, aceptan tener la edad m&iacute;nima necesaria para
                asumir la responsabilidad legal por sus acciones. Esta edad
                m&iacute;nima depender&aacute; de la legislaci&oacute;n
                aplicable en el pa&iacute;s desde donde se genera la
                suscripci&oacute;n.
              </p>
              <p>
                La informaci&oacute;n proporcionada para la creaci&oacute;n de
                la Cuenta debe ser fidedigna. El uso de cualquier dato o
                documento falso constituye una infracci&oacute;n a
                nuestros&nbsp;<strong>T&eacute;rminos y Condiciones</strong>. Lo
                anterior tambi&eacute;n aplica sobre Cuentas que hayan sido
                creadas en nombre de otras personas o sean usadas por menores de
                edad. El hallazgo de alguno de estos incumplimientos faculta a
                Cronogram para tomar las acciones que estime pertinentes, ya
                sean legales o relativas a restricciones y bloqueos sobre la
                Cuenta.
              </p>
              <p>
                No podr&aacute;n hacer uso de los servicios de Cronogram
                aquellas personas que hayan sido bloqueadas de la plataforma.
              </p>
              <h2>
                <strong>4.6. Plataforma</strong>
              </h2>
              <p>
                Es responsabilidad de Cronogram velar por la correcta
                provisi&oacute;n de las funcionalidades y herramientas presentes
                en la plataforma, as&iacute; como de las caracter&iacute;sticas
                t&eacute;cnicas necesarias para cumplir con las especificaciones
                relativas al plan suscrito. Adem&aacute;s, Cronogram
                deber&aacute; brindar soporte t&eacute;cnico a las solicitudes y
                reclamos que generen los Clientes (
                <a href="https://panel.cronogram.com.ar/documents/support-levels">
                  <strong>saber m&aacute;s sobre los niveles de soporte</strong>
                </a>
                ). Para tal efecto, Cronogram cuenta con profesionales de
                atenci&oacute;n al Cliente que prestar&aacute;n asistencia a
                trav&eacute;s de los distintos canales de soporte (Centro de
                Ayuda, correo electr&oacute;nico, l&iacute;nea
                telef&oacute;nica, chat online, entre otros).
              </p>
              <p>
                La calidad de los servicios prestados por el Cliente no son,
                bajo ninguna circunstancia, responsabilidad de Cronogram.
                Cronogram ser&aacute;, solamente, el proveedor de la plataforma
                intermediaria para acercar al Cliente con su Usuario.
              </p>
              <p>
                Es responsabilidad del Cliente contar con las condiciones
                t&eacute;cnicas m&iacute;nimas para poder operar la plataforma,
                sus Cuentas y sus Agendas asociadas; as&iacute; como
                tambi&eacute;n para proveer los servicios de tipo remoto (en
                caso de estar implementados).
              </p>
              <p>
                El Cliente es responsable por el almacenamiento y resguardo de
                informaci&oacute;n que tenga relaci&oacute;n con requerimientos
                legales, en particular los de car&aacute;cter comercial e
                impositivo. La plataforma no cuenta con las
                caracter&iacute;sticas t&eacute;cnicas requeridas para una buena
                administraci&oacute;n y almacenamiento de registros o
                documentaci&oacute;n, electr&oacute;nica o de acceso (GoBD),
                para este tipo de archivos.
              </p>
              <h2>
                <strong>4.7. Contenido</strong>
              </h2>
              <p>
                El Cliente se compromete a asegurar que los servicios ofrecidos,
                las descripciones de &eacute;stos y todo el contenido expuesto
                en la plataforma no violan ninguna prohibici&oacute;n legal o
                derechos de terceros (en particular, pero no de manera
                exclusiva: nombres, protecci&oacute;n de datos, derechos de
                comercializaci&oacute;n y propiedad intelectual). Adem&aacute;s,
                se compromete a no ofrecer servicios, a trav&eacute;s de la
                plataforma, ni comunicar descripciones de servicio o contenido
                que est&eacute;:
              </p>
              <ul>
                <li>
                  <p>
                    Relacionado a lo sexual, pornograf&iacute;a u otro contenido
                    da&ntilde;ino para menores.
                  </p>
                </li>
                <li>
                  <p>
                    Relacionado al engrandecimiento de la violencia e
                    incitaci&oacute;n al odio.
                  </p>
                </li>
                <li>
                  <p>
                    Relacionado a la promoci&oacute;n o provisi&oacute;n de
                    lineamientos para cometer delitos.
                  </p>
                </li>
                <li>
                  <p>
                    Infringiendo cualquier prohibici&oacute;n nacional, ya sea
                    legal u oficial.
                  </p>
                </li>
              </ul>
              <p>
                Cronogram no est&aacute; obligado a revisar los contenidos ni la
                informaci&oacute;n dispuesta por el Cliente. Sin embargo, se
                reserva el derecho para terminar la relaci&oacute;n contractual,
                sin notificar, en caso que se hayan transgredido las
                prohibiciones expuestas. Cronogram siempre otorgar&aacute; al
                Cliente la posibilidad de realizar descargos.
              </p>
              <p>
                El Cliente deber&aacute; liberar a Cronogram de toda
                responsabilidad asociada a demandas de terceros, que sean
                v&aacute;lidas debido a la violaci&oacute;n de sus derechos
                (debido a la incorporaci&oacute;n, por parte del Cliente, en sus
                contenidos), espec&iacute;ficamente, pero no de manera
                exclusiva: derechos de autor, derechos de marca, derechos
                personales u otros derechos de propiedad. Lo mismo aplica en
                caso de demandas, provenientes de las autoridades u otras
                entidades, producto de las violaciones a los derechos antes
                mencionados, por las cuales el Cliente es responsable.
              </p>
              <p>
                Adem&aacute;s, el Cliente se compromete a reembolsar a Cronogram
                por todos los gastos incurridos para defenderse de acciones
                legales justificadas por parte de terceros y que sean resultado
                de la violaci&oacute;n de sus derechos por parte de &eacute;l.
                Este reembolso comprender&aacute; en particular, pero no de
                manera exclusiva, los costos necesarios para una defensa legal
                razonable. El Cliente no est&aacute; obligado a reembolsar en
                caso de no ser el culpable de la violaci&oacute;n a los derechos
                de terceras partes.
              </p>
              <h1>
                <strong>5. Derechos</strong>
              </h1>
              <h2>
                <strong>5.1. Licencia</strong>
              </h2>
              <p>
                Cliente y Usuario, sin distinci&oacute;n, son propietarios de
                toda la informaci&oacute;n proporcionada, es decir del contenido
                y datos personales que nos han suministrado, tanto por medio de
                nuestro sitio web como a trav&eacute;s de la plataforma.
              </p>
              <p>
                La suscripci&oacute;n por parte del Cliente, a la plataforma de
                agendamiento online, lo faculta para hacer uso de las funciones
                y herramientas que &eacute;sta provee (de acuerdo al plan
                seleccionado), durante el tiempo contratado o hasta que
                Cronogram decida terminar o anular dicha suscripci&oacute;n.
                Cualquier uso indebido o fraudulento de la plataforma, permite a
                Cronogram tomar las acciones que estime pertinentes, ya sean
                legales o relativas a la prohibici&oacute;n y anulaci&oacute;n
                de uso de la licencia.
              </p>
              <p>
                Una vez aceptados los&nbsp;
                <strong>T&eacute;rminos y Condiciones</strong> expuestos en este
                documento, tanto el Cliente como el Usuario, confieren a
                Cronogram la siguiente licencia no exclusiva:
              </p>
              <p>
                Un derecho mundial, transferible y sujeto a sublicencia para
                usar, copiar, modificar, distribuir, publicar y tratar la
                informaci&oacute;n y el contenido que nos proporcionen a
                trav&eacute;s de nuestros servicios, sin ning&uacute;n
                consentimiento adicional, notificaci&oacute;n o
                compensaci&oacute;n para ellos o terceros. Estos derechos se
                limitan de los siguientes modos:
              </p>
              <ul>
                <li>
                  <p>
                    Pueden finalizar esta licencia, para un contenido en
                    concreto, borrando dicho contenido o, en general, cerrando
                    la Cuenta asociada; salvo las siguientes situaciones: (1)
                    que haya sido compartido en espacios de interacci&oacute;n
                    p&uacute;blicos dentro de la plataforma y otras personas
                    (Clientes o Usuarios) hayan copiado, compartido o almacenado
                    dicho contenido y (2) durante el tiempo razonable que tome
                    el retirar el contenido de las copias de seguridad y otros
                    sistemas asociados.
                  </p>
                </li>
                <li>
                  <p>
                    Pueden solicitar la eliminaci&oacute;n de toda la
                    informaci&oacute;n almacenada, por medio de una solicitud
                    manifestando tal requerimiento al correo
                    electr&oacute;nico&nbsp;
                    <a href="mailto:soporte@cronogram.com.ar">
                      <strong>soporte@cronogram.com.ar</strong>
                    </a>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    No incluiremos su contenido en publicidad para productos o
                    servicios de terceros sin su consentimiento personal
                    (incluido el contenido patrocinado). No obstante, tenemos el
                    derecho, sin indemnizarlos ni a ellos ni a terceros, a
                    realizar anuncios respecto del contenido p&uacute;blico y
                    las acciones sociales que desarrollen dentro de la
                    plataforma. Adem&aacute;s, es posible que hagamos
                    menci&oacute;n al uso de alguna funcionalidad de los
                    servicios que utilicen, usando su nombre o
                    fotograf&iacute;a, con el fin de promocionar dicha
                    funcionalidad.
                  </p>
                </li>
                <li>
                  <p>
                    Necesitaremos su consentimiento si queremos otorgar, a
                    terceros, el derecho de publicar su contenido fuera de los
                    servicios. No obstante, si el contenido compartido ha sido
                    difundido en espacios de interacci&oacute;n p&uacute;blicos,
                    se permitir&aacute; que sea mostrado en los distintos
                    servicios de motores de b&uacute;squeda.
                  </p>
                </li>
                <li>
                  <p>
                    Aunque podemos editar y realizar modificaciones al formato
                    del contenido publicado (como traducirlo, transcribirlo,
                    cambiar la tipograf&iacute;a, el dise&ntilde;o o el tipo de
                    archivo, o eliminar metadatos), no modificaremos el
                    significado.
                  </p>
                </li>
                <li>
                  <p>
                    Como son propietarios de su contenido e informaci&oacute;n,
                    y solo poseemos derechos no exclusivos respecto de los
                    mismos, pueden escoger compartirlos con terceros, incluido a
                    trav&eacute;s de una licencia de Creative Commons.
                  </p>
                </li>
              </ul>
              <p>
                El Cliente afirma que est&aacute; completamente autorizado para
                otorgar los derechos correspondientes y la utilizaci&oacute;n de
                los mismos. Adem&aacute;s, al otorgar dichos derechos, el
                Cliente asegura no infringir ning&uacute;n derecho de terceras
                partes, en particular los derechos personales o de autor de
                terceros involucrados en la creaci&oacute;n de los contenidos.
              </p>
              <h2>
                <strong>5.2. Disponibilidad de los Servicios</strong>
              </h2>
              <p>
                Cronogram tiene la facultad para cambiar, suspender o finalizar
                un servicio cuando estime conveniente. Adem&aacute;s, puede
                modificar los precios en el futuro, previa notificaci&oacute;n
                dentro de un plazo m&iacute;nimo de 30 d&iacute;as y en la
                medida en que la legislaci&oacute;n vigente lo permita.
              </p>
              <h2>
                <strong>5.3. Contenido</strong>
              </h2>
              <p>
                Los Clientes pueden publicar la informaci&oacute;n o contenido
                que estimen pertinente para promover sus servicios dentro de la
                plataforma, siempre y cuando: (1) &eacute;ste no sea de
                car&aacute;cter sensible (es aquel que fomenta malos
                h&aacute;bitos y promueve valores negativos, aquellas
                im&aacute;genes o videos perturbadores, o informaci&oacute;n
                violenta, pornogr&aacute;fica o escatol&oacute;gica) ni (2)
                promueva el desarrollo de actividades il&iacute;citas de acuerdo
                a la legislaci&oacute;n vigente.
              </p>
              <p>
                El uso que le den, Clientes o Usuarios, a la informaci&oacute;n
                compartida por otros Usuarios y Clientes dentro de la
                plataforma, depender&aacute; de su exclusiva responsabilidad,
                asumiendo por s&iacute; mismos los riesgos asociados.
              </p>
              <p>
                Por lo general, Cronogram no revisa el contenido ni la
                informaci&oacute;n proporcionada en la plataforma, por lo que no
                nos haremos responsables por el uso o da&ntilde;o que puedan
                causar. Es deber de los Clientes y Usuarios informar o reportar,
                directamente a&nbsp;
                <a href="mailto:soporte@cronogram.com.ar">
                  <strong>soporte@cronogram.com.ar</strong>
                </a>
                , cuando evidencien una situaci&oacute;n en la que se divulgue o
                comparta informaci&oacute;n que incorpore contenido sensible.
              </p>
              <h2>
                <strong>5.4. Derechos de propiedad intelectual</strong>
              </h2>
              <p>
                Cronogram se reserva todos los derechos de propiedad intelectual
                de su plataforma online, as&iacute; como los productos y
                servicios asociados a &eacute;sta. Cronogram y otras marcas de
                Cronogram, las marcas de servicio, las im&aacute;genes y los
                logotipos utilizados son marcas registradas de Cronogram.
              </p>
              <p>
                Las marcas y los logotipos utilizados por los Clientes son de su
                propiedad y, en caso de que &eacute;stas infrinjan alg&uacute;n
                derecho, se proceder&aacute; de acuerdo a lo establecido en el
                punto 4.7. de Contenido, secci&oacute;n Obligaciones del
                presente documento.
              </p>
              <h2>
                <strong>5.5. Procesamiento automatizado</strong>
              </h2>
              <p>
                Utilizamos datos e informaci&oacute;n que nos facilitan, tanto
                Clientes como Usuarios, para hacer sugerencias relevantes.
              </p>
              <p>
                En el caso de los Usuarios, que deben haber creado una Cuenta
                para poder reservar los servicios del negocio que desean, se
                utilizar&aacute; la misma informaci&oacute;n de la
                creaci&oacute;n de cuenta para facilitar sus reservas en esa
                tienda. Sin embargo, si desean reservar en otro negocio afiliado
                a Cronogram, deber&aacute;n crear una nueva cuenta. El
                n&uacute;mero de cuentas del Usuario ser&aacute; equivalente a
                la cantidad de negocios donde ha reservado y la
                informaci&oacute;n no se comparte entre negocios afiliados a
                Cronogram.
              </p>
              <h1>
                <strong>6. Garant&iacute;as</strong>
              </h1>
              <p>
                Cronogram es responsable por defectos acorde a las regulaciones
                que establezca la legislaci&oacute;n vigente. Cronogram
                garantiza que la calidad de la plataforma de agendamiento online
                se mantendr&aacute; durante todo el per&iacute;odo contratado y
                que no existir&aacute;n derechos de terceras partes, que impidan
                el uso contractual de dicha herramienta. Cronogram debe
                solucionar o corregir, en un per&iacute;odo de tiempo razonable,
                cualquiera error o defecto que la plataforma presente.
              </p>
              <p>
                Los defectos, errores o intermitencias; que sean producto de
                condiciones ambientales, manejo inadecuado, incumplimiento de
                las instrucciones de uso o de las especificaciones
                t&eacute;cnicas, por parte del Cliente, no ser&aacute;n
                responsabilidad de Cronogram. Debido a lo anterior, los derechos
                del Cliente en relaci&oacute;n a defectos o errores
                quedar&aacute;n excluidos, en particular, si &eacute;ste no ha
                cumplido en usar la plataforma como ha sido establecido y ha:
              </p>
              <ul>
                <li>
                  <p>
                    Hecho modificaciones o cambios en la plataforma o sus
                    componentes, pudiendo ser &eacute;l o terceros los
                    responsables de dichas modificaciones.
                  </p>
                </li>
                <li>
                  <p>
                    Violado las instrucciones y requerimientos de uso
                    establecidos por Cronogram.
                  </p>
                </li>
              </ul>
              <p>
                Cronogram est&aacute; siempre esforz&aacute;ndose para
                incorporar o desarrollar mejoras que potencien la plataforma,
                pero no est&aacute; obligado a aquello. En particular, Cronogram
                no est&aacute; obligado a adaptar su plataforma ni ninguno de
                sus componentes a cambios en los servicios o interfaces de
                terceros.
              </p>
              <p>
                En todo los dem&aacute;s aspectos, aplicar&aacute; lo estipulado
                en nuestro&nbsp;
                <strong>
                  <a href="https://panel.cronogram.com.ar/documents/service-level-agreement">
                    SLA
                  </a>
                </strong>{" "}
                y&nbsp;
                <strong>
                  <a href="https://panel.cronogram.com.ar/documents/support-levels">
                    Niveles de Soporte
                  </a>
                </strong>
                .
              </p>
              <h1>
                <strong>
                  7. Exenci&oacute;n y limitaci&oacute;n de responsabilidad
                </strong>
              </h1>
              <h2>
                <strong>7.1. Exenci&oacute;n de garant&iacute;a</strong>
              </h2>
              <p>
                Renunciamos a cualquier responsabilidad jur&iacute;dica derivada
                de la calidad, seguridad o fiabilidad de nuestros servicios.
              </p>
              <p>
                NI CRONOGRAM NI NINGUNO DE SUS AFILIADOS PROPORCIONAR&Aacute;N
                DECLARACIONES O GARANT&Iacute;A SOBRE LOS SERVICIOS, INCLUIDA
                CUALQUIER DECLARACI&Oacute;N QUE ESTABLEZCA QUE LOS SERVICIOS
                SER&Aacute;N ININTERRUMPIDOS O QUE EST&Eacute;N LIBRES DE
                ERRORES, Y PROPORCIONAR&Aacute;N DICHOS SERVICIOS (INCLUIDOS
                CONTENIDO E INFORMACI&Oacute;N) &laquo;TAL CUAL&raquo; Y
                &laquo;SEG&Uacute;N DISPONIBILIDAD&raquo;. EN LA MEDIDA EN QUE
                LO PERMITA LA LEGISLACI&Oacute;N APLICABLE, CRONOGRAM Y SUS
                AFILIADOS DECLARAN LA EXENCI&Oacute;N DE CUALQUIER TIPO DE
                GARANT&Iacute;A IMPL&Iacute;CITA O LEGAL, INCLUIDAS LAS
                GARANT&Iacute;AS IMPL&Iacute;CITAS DE T&Iacute;TULO,
                PRECISI&Oacute;N DE LOS DATOS, NO INFRACCI&Oacute;N,
                COMERCIABILIDAD O IDONEIDAD PARA UN FIN DETERMINADO.
              </p>
              <h2>
                <strong>7.2. Exenci&oacute;n de responsabilidad</strong>
              </h2>
              <p>
                Estas son las limitaciones de responsabilidad jur&iacute;dica
                que podemos tener en relaci&oacute;n a nuestros Clientes y
                Usuarios.
              </p>
              <p>
                EN LA MEDIDA EN QUE LO PERMITA LA LEY (Y A MENOS QUE CRONOGRAM
                PARTICIPE EN UN ACUERDO POR ESCRITO INDEPENDIENTE QUE ANULE ESTE
                CONTRATO), CRONOGRAM Y SUS AFILIADOS, NO SER&Aacute;N
                RESPONSABLES, EN RELACI&Oacute;N CON ESTE CONTRATO, DE LA
                P&Eacute;RDIDA DE BENEFICIOS O DE OPORTUNIDADES DE NEGOCIO, DE
                LA REPUTACI&Oacute;N (POR EJEMPLO, DECLARACIONES OFENSIVAS O
                DIFAMATORIAS), DE LA P&Eacute;RDIDA DE DATOS (POR EJEMPLO,
                TIEMPO DE INACTIVIDAD O P&Eacute;RDIDA, USO O CAMBIOS EN LA
                INFORMACI&Oacute;N O EL CONTENIDO) O DE CUALQUIER DA&Ntilde;O
                INDIRECTO, ACCIDENTAL, RESULTANTE COMO CONSECUENCIA, ESPECIAL O
                DE CAR&Aacute;CTER PUNITIVO.
              </p>
              <p>
                CRONOGRAM Y SUS AFILIADOS NO SER&Aacute;N RESPONSABLES ANTE SUS
                CLIENTES, EN RELACI&Oacute;N CON ESTE CONTRATO, POR NINGUNA
                CANTIDAD QUE EXCEDA (A) LOS GASTOS TOTALES ABONADOS O PENDIENTES
                DE ABONAR POR SU PARTE A CRONOGRAM POR LOS SERVICIOS PRESTADOS
                DURANTE EL PLAZO ESTABLECIDO POR ESTE CONTRATO, SI LOS HUBIERA.
              </p>
              <h2>
                <strong>7.3. Bases contractuales. Exenciones</strong>
              </h2>
              <p>
                Las limitaciones de responsabilidad de esta secci&oacute;n
                (Exenci&oacute;n y limitaci&oacute;n de responsabilidad) forman
                parte del acuerdo establecido en el presente documento, entre
                Cronogram y sus Clientes y Usuarios. Dichas limitaciones se
                aplicar&aacute;n a todas las reclamaciones de responsabilidad
                (como las que se deriven de la garant&iacute;a, el da&ntilde;o,
                la negligencia, las responsabilidades contractuales o legales),
                incluso aunque haya sido comunicado a Cronogram y sus afiliados
                la posibilidad de este da&ntilde;o, e incluso aunque estas
                soluciones no cumplan su objetivo primordial.
              </p>
              <p>
                Estas limitaciones de responsabilidad quedar&aacute;n sin
                aplicaci&oacute;n en caso de responsabilidad por fraude,
                negligencia grave o infracci&oacute;n dolosa, o en casos de
                negligencia que se haya infringido una obligaci&oacute;n
                material, entendida como aquella que constituye un requisito
                previo para la prestaci&oacute;n de nuestros servicios y en la
                que, tanto los Clientes como los Usuarios, pueden confiar
                razonablemente, pero solo en la medida que los da&ntilde;os se
                debieran directamente al incumplimiento y fueran previsibles al
                t&eacute;rmino de este contrato y siempre que sea considerados
                t&iacute;picos en el contexto del mismo.
              </p>
              <h1>
                <strong>8. Finalizaci&oacute;n del contrato</strong>
              </h1>
              <p>
                Cualquiera de las Partes puede poner fin a este contrato, sin
                perjuicio de la continuidad de determinados derechos y
                obligaciones.
              </p>
              <p>
                Una vez finalice el contrato, el Cliente o el Usuario que haya
                decidido cerrar su cuenta asociada, no podr&aacute; acceder ni
                utilizar los servicios. Lo descrito a continuaci&oacute;n
                tendr&aacute; vigencia, incluso tras la finalizaci&oacute;n:
              </p>
              <ul>
                <li>
                  <p>
                    Derecho de Cronogram a utilizar y divulgar el contenido
                    transmitido en espacios de interacci&oacute;n
                    p&uacute;blicos (comentarios de retroalimentaci&oacute;n,
                    comentarios en el Centro de Ayuda, entre otros).
                  </p>
                </li>
                <li>
                  <p>Las secciones 7, 9, 10 y 11.2.</p>
                </li>
                <li>
                  <p>
                    Cualquier importe adeudado por el Cliente, antes de la
                    finalizaci&oacute;n del contrato, continuar&aacute;
                    debi&eacute;ndose.
                  </p>
                </li>
              </ul>
              <h1>
                <strong>9. Disposiciones generales</strong>
              </h1>
              <p>
                A continuaci&oacute;n, se describen algunos detalles importantes
                sobre el contrato.
              </p>
              <p>
                Si un tribunal con autoridad sobre este contrato determina que
                no puede aplicarse alguna parte del mismo, tanto el Cliente como
                el Usuario y nosotros aceptamos que el tribunal modifique los
                t&eacute;rminos para que esa parte s&iacute; pueda aplicarse y
                siga sirviendo su prop&oacute;sito. Si el tribunal no pudiera
                conseguirlo; el Cliente, el Usuario y nosotros acordamos pedir
                al tribunal que elimine esa parte que no puede aplicarse y que
                mantenga el resto del contrato.
              </p>
              <p>
                Este contrato (incluidos los t&eacute;rminos adicionales que
                establezcamos cuando el Cliente o el Usuario interact&uacute;en
                con una funcionalidad de los servicios) es el &uacute;nico
                acuerdo entre nosotros, sobre los servicios, y reemplaza todos
                los acuerdos anteriores sobre los servicios.
              </p>
              <p>
                Si no actuamos en caso de incumplimiento de este contrato, ello
                no significa que Cronogram haya renunciado a su derecho a hacer
                valer el contrato. Ni el Cliente ni el Usuario, pueden ceder o
                transferir este contrato (o su cuenta o uso de los Servicios) a
                nadie sin nuestro consentimiento. No obstante, aceptan que
                Cronogram ceda este contrato a sus filiales o a una tercera
                parte que lo compre sin su consentimiento. No existen otros
                terceros beneficiarios del contrato.
              </p>
              <p>
                Aceptan que el &uacute;nico modo de enviarnos una
                notificaci&oacute;n legal ser&aacute; en la direcci&oacute;n
                proporcionada en la Secci&oacute;n 14.
              </p>
              <h1>
                <strong>
                  10. Legislaci&oacute;n aplicable y resoluci&oacute;n de
                  conflictos
                </strong>
              </h1>
              <p>
                En el caso, improbable, que se produzca una disputa legal, tanto
                el Cliente como el Usuario y Cronogram, se comprometen a
                someterse a los tribunales y la jurisdicci&oacute;n de Chile.
              </p>
              <p>
                Esta secci&oacute;n no anular&aacute; la aplicaci&oacute;n de
                ning&uacute;n tipo de protecci&oacute;n obligatoria al
                consumidor, al amparo de la legislaci&oacute;n del pa&iacute;s
                de residencia habitual del Cliente o del Usuario, desde el cual
                hacen uso de la plataforma de Cronogram y sus servicios.
              </p>
              <p>
                Tanto el Cliente como el Usuario, acuerdan que la
                legislaci&oacute;n de Chile prevalecer&aacute; en cualquier
                disputa relacionada con este documento de&nbsp;
                <strong>T&eacute;rminos y Condiciones</strong>, los servicios,
                productos u otros relacionados de Cronogram. Tambi&eacute;n,
                acuerdan que cualquier tipo de reclamaci&oacute;n o disputa se
                resolver&aacute; &uacute;nicamente en los tribunales de justicia
                de Santiago, Chile; pues &eacute;sta ser&aacute; la
                jurisdicci&oacute;n del tribunal competente.
              </p>
              <h1>
                <strong>11. Lo que se debe y lo que no se debe hacer</strong>
              </h1>
              <h2>
                <strong>11.1. Lo que se debe hacer</strong>
              </h2>
              <p>
                Cronogram es una plataforma de agendamiento online. Esta lista
                es sobre lo que tienen permitido hacer Clientes y Usuarios:
              </p>
              <p>Aceptan que van a:</p>
              <ul>
                <li>
                  <p>
                    Cumplir todas las leyes pertinentes, incluidas, entre otras,
                    las leyes de privacidad, las leyes de propiedad intelectual,
                    las leyes de antispam, las leyes de control de las
                    exportaciones, las leyes en materia fiscal y tributaria, y
                    otros requisitos regulatorios.
                  </p>
                </li>
                <li>
                  <p>
                    Proporcionarnos informaci&oacute;n exacta y mantenerla
                    actualizada.
                  </p>
                </li>
                <li>
                  <p>Usar informaci&oacute;n ver&iacute;dica y propia.</p>
                </li>
                <li>
                  <p>Proveer y usar los servicios de manera profesional.</p>
                </li>
              </ul>
              <h2>
                <strong>11.2. Lo que no se debe hacer</strong>
              </h2>
              <p>
                Esta lista es sobre lo que no tienen permitido hacer Clientes y
                Usuarios:
              </p>
              <p>Aceptan que no van a:</p>
              <ul>
                <li>
                  <p>
                    Crear una identidad falsa en Cronogram, falsificar su
                    identidad, crear un perfil para alguien que no sea el propio
                    Cliente o Usuario, ni usar o intentar usar la cuenta de otra
                    persona.
                  </p>
                </li>
                <li>
                  <p>
                    Desarrollar, apoyar o utilizar programas, dispositivos,
                    scripts, robots o cualquier otro medio o proceso (incluidos
                    crawlers, plugins de navegaci&oacute;n y complementos, o
                    cualquier otra tecnolog&iacute;a) para plagiar los servicios
                    o copiar de otro modo perfiles u otros datos de los
                    servicios.
                  </p>
                </li>
                <li>
                  <p>
                    Eludir cualquier funcionalidad de seguridad o sortear
                    cualquier control de acceso o los l&iacute;mites de uso de
                    los servicios.
                  </p>
                </li>
                <li>
                  <p>
                    Copiar, utilizar, divulgar o distribuir cualquier
                    informaci&oacute;n obtenida de los servicios, ya sea de
                    forma directa o a trav&eacute;s de terceros (como los
                    motores de b&uacute;squeda), sin el consentimiento de
                    Cronogram.
                  </p>
                </li>
                <li>
                  <p>
                    Revelar informaci&oacute;n que no tienen derecho a compartir
                    (como informaci&oacute;n confidencial de terceros, incluida
                    su empresa).
                  </p>
                </li>
                <li>
                  <p>
                    Infringir los derechos de propiedad intelectual de terceros,
                    como derechos de autor, patentes, marcas, secretos
                    comerciales u otros derechos de propiedad.
                  </p>
                </li>
                <li>
                  <p>
                    Infringir la propiedad intelectual u otros derechos de
                    Cronogram, incluido, sin limitaci&oacute;n, (i) copiar o
                    distribuir nuestros v&iacute;deos de aprendizaje u otros
                    materiales; o (ii) copiar o distribuir nuestra
                    tecnolog&iacute;a; (iii) utilizar el t&eacute;rmino
                    &laquo;Cronogram&raquo; o nuestros logotipos en cualquier
                    nombre comercial, correo electr&oacute;nico o URL excepto en
                    los casos con autorizaci&oacute;n por escrito de Cronogram.
                  </p>
                </li>
                <li>
                  <p>
                    Publicar cualquier cosa que contenga virus
                    inform&aacute;ticos, gusanos o cualquier otro c&oacute;digo
                    da&ntilde;ino.
                  </p>
                </li>
                <li>
                  <p>
                    Utilizar t&eacute;cnicas de ingenier&iacute;a inversa,
                    descompilar, desarmar, descifrar o de otro modo tratar de
                    obtener el c&oacute;digo fuente de los servicios o de
                    cualquier tecnolog&iacute;a relacionada que no sea de
                    c&oacute;digo abierto.
                  </p>
                </li>
                <li>
                  <p>
                    Dar a entender o declarar que est&aacute;s asociado o
                    avalado por Cronogram sin nuestro consentimiento expreso.
                  </p>
                </li>
                <li>
                  <p>
                    Alquilar, prestar, comercializar, vender/revender u obtener
                    cualquier otro tipo de beneficio de los servicios o datos
                    relacionados, ni acceder a los mismos sin el consentimiento
                    de Cronogram.
                  </p>
                </li>
                <li>
                  <p>
                    Utilizar bots u otros m&eacute;todos automatizados para
                    acceder a los servicios.
                  </p>
                </li>
                <li>
                  <p>
                    Controlar la disponibilidad, el rendimiento o el
                    funcionamiento de los servicios con fines competitivos.
                  </p>
                </li>
                <li>
                  <p>
                    Llevar a cabo pr&aacute;cticas de &laquo;framing&raquo; o
                    &laquo;mirroring&raquo;, o simular de otro modo la
                    apariencia o funci&oacute;n de los servicios.
                  </p>
                </li>
                <li>
                  <p>
                    Superponer o modificar de cualquier otro modo los servicios
                    o su apariencia (como, por ejemplo, insertando elementos en
                    los servicios o eliminando, tapando o disimulando anuncios
                    incluidos en los servicios).
                  </p>
                </li>
                <li>
                  <p>
                    Interferir en el funcionamiento o cargar los servicios de
                    manera poco razonable (por ejemplo, spam, ataques de
                    denegaci&oacute;n de servicio, virus o algoritmos de juego).
                  </p>
                </li>
                <li>
                  <p>
                    Infringir cualquier otro t&eacute;rmino adicional relativo a
                    un servicio espec&iacute;fico proporcionado cuando inicias
                    sesi&oacute;n o comienzas a utilizar dicho servicio.
                  </p>
                </li>
              </ul>
              <h1>
                <strong>12. Enlaces externos y otros sitios web</strong>
              </h1>
              <p>
                Cronogram podr&aacute; alojar enlaces de p&aacute;ginas web de
                terceros que, a su vez, contengan enlaces a nuevas direcciones.
                En ning&uacute;n caso, Cronogram, asumir&aacute; la
                responsabilidad proveniente de alg&uacute;n tipo de da&ntilde;o
                o perjuicio generado por el contenido o elementos maliciosos
                presentes en aquellas direcciones o p&aacute;ginas web. Es de
                exclusiva responsabilidad de los Clientes y Usuarios asumir los
                riesgos que conlleva visitar enlaces externos a Cronogram. Lo
                anterior incluye los enlaces de p&aacute;ginas web establecidos
                por los Clientes.
              </p>
              <p>
                No importa la forma que asuman dichos enlaces o direcciones
                externas (banner, url, publicidad, entre otras) a Cronogram, el
                da&ntilde;o o perjuicio causado deber&aacute; ser asumido por
                los administradores de aquellos enlaces o p&aacute;ginas web y,
                por ende, deber&aacute;n ser ellos los receptores de cualquier
                tipo de acci&oacute;n legal a emprender.
              </p>
              <h1>
                <strong>13. Marketing y comunicaciones</strong>
              </h1>
              <p>
                Cronogram utilizar&aacute;, como v&iacute;a de
                comunicaci&oacute;n principal y oficial, el correo
                electr&oacute;nico proporcionado por el Cliente o Usuario. En
                caso de que ya no exista relaci&oacute;n contractual entre las
                partes, Cronogram seguir&aacute; haciendo uso de la
                direcci&oacute;n proporcionada con fines comerciales, como por
                ejemplo difusi&oacute;n de campa&ntilde;as publicitarias,
                informaci&oacute;n de sus productos y servicios, entre otros.
              </p>
              <p>
                Tanto el Cliente como el Usuario tienen el derecho, en el
                momento que estimen conveniente, de revocar, a Cronogram, el uso
                de su direcci&oacute;n de correo electr&oacute;nico como
                v&iacute;a de contacto comercial.
              </p>
              <h1>
                <strong>14. Contacto</strong>
              </h1>
              <p>
                Para cualquier tipo de consulta, duda, sugerencia o reclamo; el
                Cliente al igual que el Usuario, podr&aacute;n ponerse en
                contacto con ejecutivos de Cronogram por medio del correo
                electr&oacute;nico&nbsp;
                <a href="mailto:soporte@cronogram.com.ar">
                  <strong>soporte@cronogram.com.ar</strong>
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terminos;
