import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es";

const FiltroTurnos = ({ fecha, turnos, handleChangeHora, profesional, registro }) => {
  const [filteredTurno, setFilteredTurno] = useState(null);

  useEffect(() => {
    calculo(fecha);
  }, [fecha]);

  const horario = [];
let top = 0;

for (let hora = 8; hora <= 23; hora++) {
  const minutos = ["00", "30"];
//  const minutos = ["00", "15", "30", "45"];
  minutos.forEach((minuto) => {
    const id = hora - 7 + minutos.indexOf(minuto);
    horario.push({
      id,
      top,
      hora: hora.toString() + ":" + minuto,
    });
    top += 39;
  });
}

  function calculo() {
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();
    const currentTime = currentHour + currentMinutes / 60;
    const fechaActual = moment()
    if (turnos) {
      const turnosDia = turnos.filter(
        (x) =>
          x.fecha === fecha &&
          Number(x.id_prestador) === Number(profesional)
      );
      const reservedTimes = turnosDia.map((turno) => {
        const [hours, minutes] = turno.hora.split(":");
        return parseFloat(hours) + parseFloat(minutes) / 60;
      });

      let filteredArray;

      if (fecha === fechaActual.format("YYYY-MM-DD")) {
        filteredArray = horario.filter((item1) => {
          const [hours, minutes] = item1.hora.split(":");
          const itemTime = parseFloat(hours) + parseFloat(minutes) / 60;
          return currentTime < itemTime && !reservedTimes.includes(itemTime);
        });
      } else {
        filteredArray = horario.filter((item1) => {
          const [hours, minutes] = item1.hora.split(":");
          const itemTime = parseFloat(hours) + parseFloat(minutes) / 60;
          return !reservedTimes.includes(itemTime);
        });
      }
      setFilteredTurno(filteredArray);
    } else {
      let filteredArray;

      if (fecha === now.toISOString().split("T")[0]) {
        filteredArray = horario.filter((item1) => {
          const [hours, minutes] = item1.hora.split(":");
          const itemTime = parseFloat(hours) + parseFloat(minutes) / 60;
          return currentTime < itemTime;
        });
      } else {
        filteredArray = horario; // Todos los horarios son considerados disponibles en este caso
      }
      setFilteredTurno(filteredArray);
    }
  }



  return (
    <div className="pt-5 mb-5" 
        style={{display: 'flex',
                gap: '10px',
                flexWrap: 'wrap',

    }}>
      {filteredTurno ? (
        filteredTurno.map((d, i) => (
          <div style={{
            border: 
                d.hora === registro
                ? "1px solid #736ced"
                : "1px solid #9f9f9f",
            background: 
                d.hora === registro
                ? "#736ced"
                : "#fff",
            color: 
                d.hora === registro
                ? "#fff"
                : "#9f9f9f",
            borderRadius: "5px",
            width:'50px'
          }}
          key={i} onClick={() => handleChangeHora(d.hora)}>
           <small> {d.hora}</small>
          </div>
        ))
      ) : (
        <div>No hay horarios disponibles</div>
      )}
    </div>
  );
};

export default FiltroTurnos;