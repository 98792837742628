import React, { useEffect, useState } from 'react';

const PWAInstallationButton = () => {
  const [isInstalled, setIsInstalled] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const checkInstallation = () => {
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      setIsInstalled(true);
    } else {
      setIsInstalled(false);
    }
  };

  const handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    setDeferredPrompt(event);
    checkInstallation();
  };

  const handleInstallButtonClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          console.log('El usuario ha aceptado la instalación');
        } else {
          console.log('El usuario ha rechazado la instalación');
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    checkInstallation();

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  return (
    <div>
      {!isInstalled && (
        <div id="installButton" style={{ display: 'block' }} onClick={handleInstallButtonClick}>
          Instalar
        </div>
      )}
    </div>
  );
};

export default PWAInstallationButton;
