import React, {useState, useEffect} from 'react';
import axios from "axios";
import apiConfig from "../../../services/apiConfig";
import useAuth from "../../../auth/useAuth";
import { Form, Row, Col, Button, ListGroup, FloatingLabel } from 'react-bootstrap';

const Update = (props) => {
	const auth = useAuth();
    const user = JSON.parse(localStorage.getItem('user'));
	const [locales, setLocales] = useState([]);
    const [datoSeleccionado,setDatoSeleccionado] = useState([]);

	function updateUser(event){           
			event.preventDefault();
			axios.post(apiConfig+`prestadores/update/update.php`,datoSeleccionado)
			.then((res) => {
				let {data} = res;                  
				if(data.success && data.message){ 			  }
					props.getData(datoSeleccionado)
			})
			.catch(function (res) {
				
			});		
	}

	const handleChange = (e) => {        
		setDatoSeleccionado({...datoSeleccionado,[e.target.name]:e.target.value});		
	}

	useEffect(() => {
		setDatoSeleccionado(props.data)
		axios.get(apiConfig+'business/list/?id='+Number(user.id_business_unit))
		 .then((getData) => {
			 setLocales(getData.data.data);                
		 });
	}, []);


	return  <div className="text-start">
	  <div className="col-12 pt-1">
		<Form onSubmit={updateUser}>
		  <Row>
			<Col sm={6} className="mb-2">
			  <FloatingLabel label="Nombre">
				<Form.Control
				  name="nombre"
				  type="text"
				  value={datoSeleccionado && datoSeleccionado.nombre}
				  onChange={handleChange}
				  pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
				  maxLength={20}
				/>
			  </FloatingLabel>
			</Col>
			<Col sm={6} className="mb-2">
			  <FloatingLabel label="Apellido">
				<Form.Control
				  name="apellido"
				  type="text"
				  value={datoSeleccionado && datoSeleccionado.apellido}
				  onChange={handleChange}
				  pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
				  maxLength={20}
				/>
			  </FloatingLabel>
			</Col>
		  </Row>
		  <Form.Group className="mb-2">
			<FloatingLabel label="Teléfono">
			  <Form.Control
				name="telefono"
				type="text"
				value={datoSeleccionado && datoSeleccionado.telefono}
				onChange={handleChange}
				minLength={10}
				maxLength={10}
				pattern="[0-9]+$"
			  />
			</FloatingLabel>
		  </Form.Group>
		  <Form.Group className="mb-2">
			<FloatingLabel label="Correo electrónico">
			  <Form.Control
				name="email"
				type="email"
				value={datoSeleccionado && datoSeleccionado.email}
				onChange={handleChange}
			  />
			</FloatingLabel>
			</Form.Group>
		  <Form.Group className="mb-2">
			<FloatingLabel controlId="floatingSelect" label="Local">
     			<Form.Select aria-label="Local"		
				as="select"
				name="id_business_office"
				value={datoSeleccionado && datoSeleccionado.id_business_office}
				onChange={handleChange}
				required
			  >
						<option selected></option>
						{locales && locales.map((data,index)=>(
							<option value={data.id} key={index}>{data.name}</option>
						))}
			  	</Form.Select>
    		</FloatingLabel>
		  </Form.Group>


	
	
		  <br />
		  <div className="mb-4">
			<Button type="submit" className="btn app-button shadow">
			  Guardar
			</Button>
		  </div>
		</Form>
	  </div>
	</div>;
	
};

export default Update;
