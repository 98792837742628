import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col,  Row } from "react-bootstrap";
import Moment from "moment";

const TurnosPagados = (props) => {
    const [days,setDays] = useState('');
	const [turnos,setTurnos] = useState([]);
	

	const day = props.day;
	const tur = props.turnos;
	const currentDate = props.currentDate;
	
	useEffect(() => {
		setDays(day);		
	  }, [day, currentDate])
	useEffect(()=>{
        setTurnos(tur);		
    },[tur])
    const total = turnos.filter((x)=> x.fecha === days).length;
    const calcularPorcentaje = (valor1) => {
        const porcentaje = (valor1 /  total) * 100;
    return porcentaje.toFixed(2); // Redondear a 2 decimales
      };

	  const confirmados = calcularPorcentaje(turnos.filter((x)=> x.fecha === props.day && Number(x.id_estado_pago) === 3).length);
	  const pendientes = calcularPorcentaje(turnos.filter((x)=> x.fecha === props.day && Number(x.id_estado_pago) !== 3).length);

	
  return (
    <div  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Row style={{height: '120px'}}>
            <Col>
			<div style={{
				position: 'relative',
				width: '10px',
				height: '100px',
				background: '#ebebeb',
				display: 'flex',
				alignItems: 'flex-end',
				margin: 'auto',
				borderRadius: '5px',
			}}
			
			>
				<div style={{
                    width: '10px',
                    height: confirmados ? confirmados+'px' : '0px',
                    background: '#00b746',
					borderRadius: '5px',
                }}>
                </div>
			</div>
            </Col>
            <Col>
			<div style={{
				position: 'relative',
				width: '10px',
				height: '100px',
				background: '#ebebeb',
				display: 'flex',
				alignItems: 'flex-end',
				margin: 'auto',
				borderRadius: '5px',
			}}
			
			>
                <div style={{
                    width: '10px',
                    height: pendientes ? pendientes+'px' : '0px',
                    background: '#ff3c3c',
					borderRadius: '5px',
                }}>
                </div>
			</div>
            </Col>
        </Row>
		<Row>
		<div style={{
				background: '#dcdefc',
				color: '#736ced',
				borderRadius: '5px',
			}}>
			{Moment(day).format("ddd")}<br/>
			<span style={{fontSize:'13px'}}>{Moment(day).format("DD-MM-YY")}</span>
			</div>
		</Row>
    </div>
  )
};


export default TurnosPagados;
