import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import "moment/locale/es";
import "./Agenda.css";
import horario from "./horario";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import apiConfig from "../../services/apiConfig";
import imagenSVG from "../../components/profile/user-generico.png";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import useAuth from "../../auth/useAuth";
import CustomerAdd from "../customer/CustomerAdd/CustomerAdd";
import Pay from "../../components/Pay/Pay";
import { MD5 } from "crypto-js";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode } from "swiper";
import Calendario from "./CalendarioDisponible";
import FiltroTurnos from "./FiltroTurnos";
import ClienteLista from "../../components/ClienteLista/ClienteLista";
import CategoriaServicios from "../../components/CategoriaServicios/CategoriaServicios";
import ServiciosLista from "../../components/ServiciosLista/ServiciosLista";

function Agenda(props) {
  const containerRef = useRef(null);
  const auth = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));
  const VIEWS = {
    VIEW: "view",
    VIEW1: "view1",
    VIEW2: "view2",
    VIEW3: "view3",
    VIEW4: "view4",
    VIEW5: "view5",
    VIEW6: "view6",
  };
  const [currentView, setCurrentView] = useState(VIEWS.VIEW);

  let servicios = props.servicios;
  let categoria = props.categoria;
  let clientes = props.clientes;
  let estados = props.estados;
  const cadena = "cr" + moment().format("HH:mm:ss");

  const hash = MD5(cadena).toString().slice(0, 10);

  const [validated, setValidated] = useState(false);
  const [profesional, setProfesional] = useState(props.profesional || []);
  const [profesionales, setProfesionales] = useState(props.profesionales || []);
  const [showC, setShowC] = useState(false);
  const [currentDate, setCurrentDate] = useState(props.fecha);
  function generateDates(currentDate) {
    let dates = [];
    for (let i = 0; i <= 0; i++) {
      const date = moment(currentDate).add(i, "days");
      dates.push(date);
    }
    return dates;
  }

  const dates = generateDates(currentDate); // Fechas de las columnas

  const [turnos, setTurnos] = useState(props.turnos);
  const [sinTurno, setSinTurno] = useState([]);
  const [serviciosFiltrados, setServicosFiltrados] = useState([]);
  const [informacion, setInformacion] = useState(false);
  const [registro, setRegistro] = useState({
    id_cliente: "",
    nombre: "",
    nombreServicio: "",
    id_estado_pago: 1,
    id_estado: 1,
    id_servicio: "",
    id_prestador: profesional.id,
    observaciones: "",
    fecha: "",
    hora: "",
    codigo: hash,
    id_business_office: user.id_business_office,
    id_business_unit: user.id_business_unit,
  });

  const changeView = (newView) => {
    setCurrentView(newView);
    if (newView === VIEWS.VIEW1) {
      setRegistro({
        id_cliente: "",
        nombre: "",
        nombreServicio: "",
        id_estado_pago: 1,
        id_estado: 1,
        id_servicio: "",
        id_prestador: profesional.id,
        observaciones: "",
        fecha: "",
        hora: "",
        codigo: hash,
        id_business_office: user.id_business_office,
        id_business_unit: user.id_business_unit,
      });
    }
  };

  const [dataPago, setDataPago] = useState("");

  const handleCloseC = () => {
    setShowC(false);
  };
  const handleShowC = () => {
    setShowC(true);
  };
  function handleShowD(d) {
    setDataPago(d);
    changeView(VIEWS.VIEW3);
  }
  const handleCloseD = () => {
    changeView(VIEWS.VIEW2);
  };

  const [enviado, setEnviado] = useState(false);

  function handleShowB(a) {
    setEditarTurno(false);
    setRegistro(a);
    changeView(VIEWS.VIEW2);
    enviado && setEnviado(false);
  }

  useEffect(() => {
    setCurrentDate(props.fecha);
  }, [props.fecha]);
  useEffect(() => {
    setProfesional(props.profesional);
  }, [props.profesional]);

  function handleSubmit(event) {
    event.preventDefault();
    if (
      registro.id_cliente !== "" &&
      registro.id_servicio !== "" &&
      registro.hora !== ""
    ) {
      setValidated(true);

      axios
        .post(apiConfig + `turnos/add/add.php`, registro)
        .then((res) => {
          let data = res.data;

          if (Number(data.success) === 1) {
            props.actualizarTurnos(data.data);
            setTurnos(data.data);
            setValidated(false);
            changeView(VIEWS.VIEW);
          }
        })
        .catch(function (res) {});
    } else {
      setInformacion(true);
    }
  }
  function handleSubmitB(e) {
    if (auth) {
      e.preventDefault();
      axios
        .post(apiConfig + `turnos/put/editar.php`, registro)
        .then((res) => {
          let data = res.data;

          if (Number(data.success) === 1) {
            changeView(VIEWS.VIEW);
            props.actualizarTurnos(data.data);
            setTurnos(data.data);
          }
        })
        .catch(function (res) {});
    }
  }

  function onDelete(ida) {
    let idn = {
      id: ida,
      id_business_office: registro.id_business_office,
    };
    if (window.confirm("Estás por borrar el registro, ¿estás seguro?")) {
      axios
        .post(apiConfig + `turnos/delete/delete.php`, idn)
        .then((res) => {
          let data = res.data;
          if (Number(data.success) === 1) {
            changeView(VIEWS.VIEW);
            props.actualizarTurnos(data.data);
            setTurnos(data.data);
          }
        })
        .catch((error) => {
          console.error("Error al eliminar el registro:", error);
        });
    }
  }

  function filtrarCategoria(dato) {
    let datos = servicios.filter(
      (x) => Number(x.id_categoria) === Number(dato.id)
    );
    if (datos) {
      setServicosFiltrados(datos);
      setRegistro({
        ...registro,
        precio: "0",
      });
      setCurrentView("view6");
    }
  }

  const handleChange = (e) => {
    setRegistro({ ...registro, [e.target.name]: e.target.value });
  };
  function handleChangePrestador(e) {
    setRegistro({ ...registro, id_prestador: e });
  }
  function handleChangeHora(e) {
    setRegistro({ ...registro, hora: e });
  }
  function handleChangeFecha(e) {
    setRegistro({ ...registro, fecha: e });
    //props.actualizarFechas(e)
  }
  function handleChangeEstado(e) {
    setRegistro({ ...registro, id_estado: e });
  }
  const handleChangeP = (e) => {
    setRegistro({ ...registro, id_estado_pago: e });
  };
  function handleChangeServicios(dato) {
    setRegistro({
      ...registro,
      id_servicio: dato.id,
      nombreServicio: dato.nombre,
      precio: dato.precio,
    });
    setCurrentView("view1");
  }

  const handleChangeClientes = (datos) => {
    setRegistro({
      ...registro,
      nombre: datos.seudonimo,
      id_cliente: datos.id_cliente,
      telefono: datos.telefono,
    });

    setCurrentView("view1");
  };

  const handleDragStart = (e, item) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("item", JSON.stringify(item));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e, hourIndex) => {
    e.preventDefault();
    const droppedItem = JSON.parse(e.dataTransfer.getData("item"));
    if (Number(droppedItem.id_estado) !== 6) {
      const remainingItems = turnos.filter(
        (item) => item.id !== droppedItem.id
      );
      const listaB = sinTurno.filter((item) => item.id !== droppedItem.id);
      setSinTurno(listaB);
      const hora = hourIndex;
      const newItems = {
        apellido: droppedItem.apellido,
        duracion: droppedItem.duracion,
        email: droppedItem.email,
        estado: droppedItem.estado,
        estado_pago: droppedItem.estado_pago,
        fecha: droppedItem.fecha,
        fecha_nac: droppedItem.fecha_nac,
        hora: hora,
        id: droppedItem.id,
        id_business_office: droppedItem.id_business_office,
        id_business_unit: droppedItem.id_business_unit,
        id_cliente: droppedItem.id_cliente,
        id_estado: droppedItem.id_estado,
        id_estado_pago: droppedItem.id_estado_pago,
        id_servicio: droppedItem.id_servicio,
        id_prestador: droppedItem.id_prestador,
        p_apellido: droppedItem.p_apellido,
        p_nombre: droppedItem.p_nombre,
        nombre: droppedItem.nombre,
        precio: droppedItem.precio,
        sesiones: droppedItem.sesiones,
        telefono: droppedItem.telefono,
        tratamiento: droppedItem.tratamiento,
        id_observaciones: droppedItem.id_observaciones,
        observaciones: droppedItem.observaciones,
      };

      remainingItems.push(newItems);
      setTurnos(remainingItems);
      const data = {
        id: droppedItem.id,
        id_business_office: droppedItem.id_business_office,
        id_business_unit: droppedItem.id_business_unit,
        id_cliente: droppedItem.id_cliente,
        id_estado: droppedItem.id_estado,
        id_estado_pago: droppedItem.id_estado_pago,
        id_servicio: droppedItem.id_servicio,
        id_prestador: droppedItem.id_prestador,
        id_observaciones: droppedItem.id_observaciones,
        fecha: droppedItem.fecha,
        hora: hora,
      };
      editar(data);
    }
  };

  function editar(data) {
    axios
      .post(apiConfig + `turnos/put/editar.php`, data)
      .then((res) => {
        let data = res;
        if (Number(data.success) === 1) {
          props.actualizarTurnos(data.data);

          setTurnos(data.data);
        }
      })
      .catch(function (res) {
        console.log(res);
      });
  }

  const colorMap = {
    1: "#ffba57", // "solicitado"
    2: "#39afd1", // "reservado"
    3: "#3b82f6", // "confirmado"
    4: "#fa5c7c", // "suspendido"
    5: "#9e9e9e", // "no asistió"
    6: "#0acf97", // "finalizado"
  };

  // sidebar Calendar
  const [date, setDate] = useState(moment(props.fecha));

  useEffect(() => {
    setDate(moment(props.fecha));
  }, [props.fecha]);

  const sumarUnaHora = (timeString, valor) => {
    function getMinutesText(value) {
      switch (value) {
        case 78:
          return 30;
        case 117:
          return 45;
        case 156:
          return 60;
        case 195:
          return 75;
        case 234:
          return 90;
        case 273:
          return 105;
        case 312:
          return 120;
        case 351:
          return 135;
        case 390:
          return 150;
        case 429:
          return 165;
        case 468:
          return 180;
        default:
          return "";
      }
    }

    const currentTime = new Date();
    const [hours, minutes] = timeString.split(":");
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);

    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const newMinutes = currentMinutes + getMinutesText(Number(valor));

    const newTime = new Date();
    newTime.setHours(currentHours);
    newTime.setMinutes(newMinutes);

    const formattedTime = newTime.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
    });

    return formattedTime;
  };

  const fechaActual = moment();
  const fechaValidar = fechaActual.format("YYYY-MM-DD");
  const diafec = moment(props.fecha).format("YYYY-MM-DD");

  const getTurnos = () => {
    props.getTurnos();
  };

  const today = moment();
  const endOfNextMonth = moment().add(1, "months").endOf("month");

  const fechasDisponibles = [];
  let currentDates = today.clone();
  while (currentDates.isSameOrBefore(endOfNextMonth, "day")) {
    if (currentDates.day() !== 0) {
      // Verificar si es domingo (0)
      fechasDisponibles.push({
        id: fechasDisponibles.length + 1,
        fecha: currentDates.format("YYYY-MM-DD"),
      });
    }
    currentDates.add(1, "days");
  }

  const [editarTurno, setEditarTurno] = useState(false);

  return (
    <>
      {currentView === VIEWS.VIEW && (
        <>
          <Row
            style={{
              backgroundColor: "#736ced",
              color: "white",
              borderLeft: "0.5px solid #aba5a5",
            }}
          >
            {dates &&
              dates.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="d-sm-block d-md-none"
                      onClick={props.handleViewMobil}
                    >
                      <h1>
                        <BsIcons.BsArrowLeft />
                      </h1>
                    </div>
                    {profesional && (
                      <div className="symbol symbol-35px symbol-circle">
                        <img
                          alt={profesional.nombre}
                          src={
                            profesional.avatar_url === "no-avatar.png"
                              ? imagenSVG
                              : apiConfig +
                                "prestadores/image/" +
                                profesional.avatar_url
                          }
                        />{" "}
                        {profesional.nombre}
                      </div>
                    )}

                    <div>
                      <small>{item.format("DD/MM/YYYY")}</small>
                    </div>
                    <div>
                      Turnos:{" "}
                      {turnos &&
                        turnos.filter(
                          (x) =>
                            x.fecha === moment(date).format("YYYY-MM-DD") &&
                            Number(x.id_prestador) === Number(profesional.id)
                        ).length}
                    </div>
                  </div>
                );
              })}
          </Row>
          <Row className={"agenda-scrollable"} style={{ paddingTop: "10px" }}>
            <Col className="col-2">
              {horario &&
                horario.map((data, index) => (
                  <div
                    style={{
                      height: "39px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "0.5px solid white",
                      borderRadius: "5px",
                      background: "#dcdefc",
                      color: "#727cf5",
                    }}
                    key={index}
                  >
                    {data.hora}
                  </div>
                ))}
            </Col>

            <Col className="col-10">
              <div
                style={{
                  height: "100%",
                  position: "relative",
                  padding: "0px",
                  overflow: "auto",

                  display: "flex",
                  justifyContent: "center",
                }}
                ref={containerRef}
              >
                {horario &&
                  horario.map((data, index) => {
                    return (
                      <div
                        className=" dragging"
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, data.hora)}
                        style={{
                          height: "39px",
                          top: data.top + "px",
                          paddingTop: "0px",
                          position: "absolute",
                          display: "flex",
                          flexWrap: "wrap",
                          width: "100%",
                          cursor: "pointer",
                          borderTop: "1px dashed #dcdefc",
                        }}
                        key={index}
                      >
                        {turnos &&
                          turnos
                            .filter(
                              (x) =>
                                x.hora === data.hora &&
                                x.fecha === moment(date).format("YYYY-MM-DD") &&
                                Number(x.id_prestador) ===
                                  Number(profesional.id)
                            )
                            .map((a, i) => {
                              return (
                                <div
                                  className="col dragging"
                                  draggable="true"
                                  onDragStart={(e) => handleDragStart(e, a)}
                                  key={i}
                                  style={{ zIndex: 100 }}
                                >
                                  <div
                                    className="container item"
                                    style={{
                                      background:
                                        colorMap[Number(a.id_estado)] ||
                                        "green",
                                      height:
                                        a.hora === "a confirmar"
                                          ? "39px"
                                          : Number(a.duracion) + "px",
                                      color: "white",
                                      display: "flex",
                                      padding: "10px",
                                      border: "0.5px solid white",
                                      borderRadius: "0.5rem",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={() => handleShowB(a)}
                                    >
                                      <div style={{ flex: 3 }}>
                                        <div className="d-flex align-items-center">
                                          {a.nombre},{a.apellido.substr(0, 1)}.
                                          <br /> {a.hora} -{" "}
                                          {sumarUnaHora(a.hora, a.duracion)}
                                        </div>
                                      </div>

                                      <div
                                        className="text-start"
                                        style={{ flex: 3 }}
                                        onClick={() => handleShowB(a)}
                                      >
                                        {a.tratamiento}
                                      </div>

                                      <div
                                        style={{ flex: 2 }}
                                        onClick={() => handleShowB(a)}
                                      >
                                        {a.estado}
                                        <br />
                                        {Number(a.id_estado_pago) === 2 && (
                                          <>
                                            <BsIcons.BsCheckCircle /> Pagado
                                          </>
                                        )}{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>
          <div style={{ position: "relative" }}>
            {fechaValidar <= diafec && (
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "#736ced",
                  borderRadius: "14px",
                  zIndex: "9999999",
                  width: "50px",
                  height: "50px",
                  bottom: "20px",
                  right: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1.5rem",
                  boxShadow:
                    "0 2px 4px 0 rgba(136,144,195,0.2), 0 5px 15px 0 rgba(37,44,97,0.15)",
                }}
              >
                <BsIcons.BsPlusLg
                  onClick={() => changeView(VIEWS.VIEW1)}
                  style={{ color: "#dcdefc" }}
                />
              </div>
            )}
          </div>
        </>
      )}
      {currentView === VIEWS.VIEW1 && (
        <>
          <Row
            style={{
              backgroundColor: "#736ced",
              color: "white",
              borderLeft: "0.5px solid #aba5a5",
              height: "60px",
            }}
          >
            <div className="text-start nav-cro">
              <h1>
                <BsIcons.BsArrowLeft onClick={() => changeView(VIEWS.VIEW)} />
              </h1>
              Agregar turno
            </div>
          </Row>
          <div className="mb-3 pt-3">
            <div>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="container mb-2">
                  <div className="card-cro mb-3">
                    
                      <div
                        style={{
                          justifyContent: "space-between",
                          cursor: "pointer",
                          display: "flex",
                        }}
                        onClick={() => changeView(VIEWS.VIEW4)}
                      >
                        {registro.nombre ? (
                          <>
                            <div className="text-start">
                              <small className="text-muted">Nombre</small>
                              <br />
                              {registro.nombre}
                            </div>
                            <div className="text-start">
                              <small className="text-muted">Teléfono</small>
                              <br />
                              {registro.telefono}
                            </div>
                          </>
                        ) : (
                          <>
                            <div>Seleccionar cliente</div><div><BsIcons.BsChevronRight /></div>
                          </>
                        )}
                      </div>
                   
                  </div>
                  <div className="card-cro mb-3">
                   
                      <div
                        style={{
                          justifyContent: "space-between",
                          cursor: "pointer",
                          display: "flex",
                        }}
                        onClick={() => changeView(VIEWS.VIEW5)}
                      >
                        {registro.nombreServicio ? (
                          <>
                            <div className="text-start">
                              <small className="text-muted">Servicio</small>
                              <br />
                              {registro.nombreServicio}
                            </div>
                            <div className="text-start">
                                <small className="text-muted">Total</small>
                                <br />
                                {Number(registro.precio) > 0
                                  ? Number(registro.precio).toLocaleString("es-AR", {
                                      style: "currency",
                                      currency: "ARS",
                                      minimumFractionDigits: 2,
                                    })
                                  : "$ 0,00"}
                              </div>
                          </>
                        ) : (
                          <>
                            <div>Seleccionar categoria</div><div><BsIcons.BsChevronRight /></div> 
                          </>
                        )}
                      
                    </div>
                  </div>
                </div>
                <div className="container">
                <Row className="card-cro mb-3">
                  <Col sm={12} md={6}>
                    <Calendario
                      fecha={dates}
                      turnos={turnos}
                      profesional={profesional}
                      handleChangeFecha={handleChangeFecha}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <div>Horarios disponibles</div>
                    </div>
                    <FiltroTurnos
                      turnos={turnos}
                      fecha={registro.fecha}
                      horario={horario}
                      profesional={profesional.id}
                      registro={registro.hora}
                      handleChangeHora={handleChangeHora}
                    />
                  </Col>
                </Row>
                </div>
                
                <div className="container">
                <Row className="card-cro mb-3">
                  <Col>
                    <Form.Group                      
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={handleChange}
                        name="observaciones"
                        placeholder="Notas"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                </div>
                {informacion && (
                  <Alert variant="primary">
                    Debes completar todos los campos !
                  </Alert>
                )}
                <div className="container">
                <div className="text-end card-cro mb-3">
                  <button
                    type="submit"
                    className="btn app-button  text-white shadow"
                  >
                    Guardar
                  </button>
                </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
      {currentView === VIEWS.VIEW2 && (
        <>
            <Row
            style={{
              backgroundColor: "#736ced",
              color: "white",
              borderLeft: "0.5px solid #aba5a5",
              height: "60px",
            }}
          >
            <div className="text-start nav-cro">
              <h1>
                <BsIcons.BsArrowLeft onClick={() => changeView(VIEWS.VIEW)} />
              </h1>
              Detalle turno
            </div>
          </Row>
          <div className="mb-3 pt-3">
          <div className="container mb-2">
                  <div className="card-cro mb-3">
                    
                      <div
                        style={{
                          justifyContent: "space-between",
                          cursor: "pointer",
                          display: "flex",
                        }}
                       
                      >
                        <div className="text-start">
                        <small className="text-muted">Nombre</small>
                        <br />
                        {registro.nombre}, {registro.apellido}
                      </div>
                      <div className="text-start">
                        <small className="text-muted">Teléfono</small>
                        <br />
                        {registro.telefono}
                      </div>
                        </div>
                        </div>
                        </div>
            <div className="container">
            <Row
              className="mb-3"
              style={{
                boxShadow: "0px 4px 15px rgba(79,79,79,0.2)",
                padding: "15px",
                margin: "5px",
                borderRadius: "5px",
              }}
            >
              <Col md={4}>
                <div className="text-start  mb-3">
                  Tratamiento:{" "}
                  <span className="text-muted">
                    {registro ? registro.tratamiento : " "}
                  </span>
                </div>
              </Col>
              <Col md={4}>
                <div className="text-start  mb-3">
                  Fecha:{" "}
                  <span className="text-muted">
                    {registro
                      ? moment(registro.fecha).format("DD-MM-YYYY")
                      : " "}
                  </span>
                </div>
              </Col>
              <Col md={4}>
                <div className="text-start  mb-3">
                  Hora:{"  "}
                  <span className="text-muted">
                    {" "}
                    {registro.hora} a{" "}
                    {sumarUnaHora(registro.hora, registro.duracion)}
                  </span>
                </div>
              </Col>
              <Col md={4}>
                <div className="text-start  mb-3">
                  Codígo: <span className="text-muted">{registro.codigo}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="text-start  mb-3">
                  Estado: <span className="text-muted">{registro.estado}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="text-start  mb-3">
                  Precio:{" "}
                  <span className="text-muted">
                    {registro
                      ? Number(registro.precio).toLocaleString("es-AR", {
                          style: "currency",
                          currency: "ARS",
                          minimumFractionDigits: 2,
                        })
                      : "$ 0,00"}
                  </span>
                </div>
              </Col>
            </Row>
           
            <Row
              className="mb-3 text-center"
              style={{
                boxShadow: "0px 4px 15px rgba(79,79,79,0.2)",
                padding: "15px",
                margin: "5px",
                borderRadius: "5px",
                fontSize: "0.8rem",
              }}
            >
              <Col className="mx-auto">
                <div
                  className="botonturno"
                  onClick={() => handleShowD(registro)}
                >
                  {Number(registro.id_estado_pago) === 1 ? (
                    <span>
                      <BsIcons.BsCashCoin />
                    </span>
                  ) : (
                    <BsIcons.BsCashCoin />
                  )}
                </div>
              </Col>
              {Number(registro.id_estado) !== 6 && (
                <Col className="mx-auto">
                  {!editarTurno && (
                    <div
                      className="botonturno"
                      onClick={() => setEditarTurno(true)}
                    >
                      <BsIcons.BsPencil />
                    </div>
                  )}
                </Col>
              )}
              {Number(registro.id_estado) !== 6 &&
                Number(registro.id_estado_pago) === 1 && (
                  <Col className="mx-auto">
                    <div
                      className="botonturno"
                      onClick={() => onDelete(registro.id)}
                    >
                      <BsIcons.BsTrash />
                    </div>
                  </Col>
                )}
            </Row>

            <Row>
              <Col sm={12} md={12}>
                {Number(registro.id_estado) !== 7 && (
                  <>
                    <Row
                      className="mb-3"
                      style={{
                        boxShadow: "0px 4px 15px rgba(79,79,79,0.2)",
                        padding: "15px",
                        margin: "5px",
                        borderRadius: "2px",
                      }}
                    >
                      <Col sm={12} md={12}>
                        <div
                          className="mb-3"
                          style={{ display: "flex", gap: "10px" }}
                        >
                          <div>Cambiar estado</div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                          }}
                        >
                          {estados &&
                            estados.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    border:
                                      Number(item.id) ===
                                      Number(registro.id_estado)
                                        ? colorMap[Number(registro.id_estado)]
                                        : "1px solid #9f9f9f",
                                    background:
                                      Number(item.id) ===
                                      Number(registro.id_estado)
                                        ? colorMap[Number(registro.id_estado)]
                                        : " #fff",
                                    color:
                                      Number(item.id) ===
                                      Number(registro.id_estado)
                                        ? " #fff"
                                        : " #000",

                                    borderRadius: "10px",
                                    width: "100px",
                                  }}
                                  onClick={
                                    Number(registro.id_estado) !== 6
                                      ? () => handleChangeEstado(item.id)
                                      : null
                                  }
                                >
                                  <small>{item.nombre}</small>
                                </div>
                              );
                            })}
                        </div>
                        <br />
                      </Col>
                    </Row>
                    {editarTurno && (
                      <>
                        <Row
                          className="mb-3"
                          style={{
                            boxShadow: "0px 4px 15px rgba(79,79,79,0.2)",
                            padding: "15px",
                            margin: "5px",
                            borderRadius: "5px",
                          }}
                        >
                          <Col sm={12} md={12}>
                            <div className="text-start mb-3">
                              Cambiar de prestador
                            </div>

                            <Swiper
                              slidesPerView={5}
                              spaceBetween={30}
                              freeMode={true}
                              pagination={{
                                clickable: true,
                              }}
                              modules={[FreeMode]}
                              className="mySwiper"
                            >
                              {profesionales &&
                                profesionales.map((item, index) => {
                                  return (
                                    <SwiperSlide key={index}>
                                      <div
                                        className="symbol symbol-35px symbol-circle"
                                        style={{
                                          border:
                                            item.id === registro.id_prestador
                                              ? "1px solid #736ced"
                                              : "1px solid transparent",
                                        }}
                                        onClick={() =>
                                          handleChangePrestador(item.id)
                                        }
                                      >
                                        <img
                                          key={item.id}
                                          src={
                                            item.avatar_url === "no-avatar.png"
                                              ? imagenSVG
                                              : apiConfig +
                                                "prestadores/image/" +
                                                item.avatar_url
                                          }
                                          alt="..."
                                        />
                                      </div>
                                      <br />
                                      <small
                                        style={{
                                          color:
                                            item.id === registro.id_prestador
                                              ? "#736ced"
                                              : "#000",
                                        }}
                                      >
                                        {item.nombre}
                                      </small>
                                    </SwiperSlide>
                                  );
                                })}
                            </Swiper>
                            <br />
                          </Col>
                        </Row>
                        <Row
                          className="mb-3"
                          style={{
                            boxShadow: "0px 4px 15px rgba(79,79,79,0.2)",
                            padding: "15px",
                            margin: "5px",
                            borderRadius: "5px",
                          }}
                        >
                          <Col sm={12} md={6}>
                            <Calendario
                              fecha={dates}
                              turnos={turnos}
                              profesional={profesional}
                              handleChangeFecha={handleChangeFecha}
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <div style={{ display: "flex", gap: "20px" }}>
                              <div>Horarios</div>
                            </div>
                            <FiltroTurnos
                              turnos={turnos}
                              fecha={registro.fecha}
                              horario={horario}
                              profesional={profesional.id}
                              registro={registro.hora}
                              handleChangeHora={handleChangeHora}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row
                      className="mb-3"
                      style={{
                        boxShadow: "0px 4px 15px rgba(79,79,79,0.2)",
                        padding: "15px",
                        margin: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Col sm={12} md={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control
                            as="textarea"
                            onChange={handleChange}
                            value={registro.observaciones}
                            name="observaciones"
                            placeholder="Notas"
                            rows={3}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    
                      <div className="text-end card-cro mb-3">
                        <button
                           onClick={handleSubmitB}
                          className="btn text-white shadow"
                          style={{
                            background:
                              colorMap[Number(registro.id_estado)] || "",
                            color: "white",
                          }}
                        >
                          Guardar
                        </button>
                      </div>
                   
                  </>
                )}
              </Col>
            </Row>
          </div>
          </div>
        </>
      )}
      {currentView === VIEWS.VIEW3 && (
        <>
          <div>
            <div className="text-start ">
              <h1 className="display-1">
                <BsIcons.BsArrowLeftShort
                  className="app-color"
                  onClick={() => changeView(VIEWS.VIEW2)}
                />
              </h1>
            </div>
            <div>
              <Pay
                handleCloseD={handleCloseD}
                data={dataPago}
                getTurnos={getTurnos}
                handleChangeP={handleChangeP}
              />
            </div>
          </div>
        </>
      )}
      {currentView === VIEWS.VIEW4 && (
        <>
          <div>
            <div className="text-start ">
              <h1 className="display-1">
                <BsIcons.BsArrowLeftShort
                  className="app-color"
                  onClick={() => setCurrentView("view1")}
                />
              </h1>
            </div>
            <div className="text-end">
              <h2>
                <BsIcons.BsPlusCircleFill
                  onClick={handleShowC}
                  style={{ color: "#736CED" }}
                />
              </h2>
            </div>
            <div>
              <ClienteLista
                clientes={clientes}
                handleChangeClientes={handleChangeClientes}
              />
            </div>
          </div>
          <Modal show={showC} size="lg" onHide={handleCloseC}>
            <Modal.Header closeButton>
              <h1>Alta Cliente</h1>
            </Modal.Header>
            <Modal.Body>
              <CustomerAdd
                agenda={true}
                handleCloseC={handleCloseC}
                getClientes={props.getClientes}
              />
              <br />
            </Modal.Body>
          </Modal>
        </>
      )}
      {currentView === VIEWS.VIEW5 && (
        <>
          <div>
            <div className="text-start ">
              <h1 className="display-1">
                <BsIcons.BsArrowLeftShort
                  className="app-color"
                  onClick={() => setCurrentView("view1")}
                />
              </h1>
            </div>
            <div>
              <CategoriaServicios
                categoria={categoria}
                filtrarCategoria={filtrarCategoria}
              />
            </div>
          </div>
        </>
      )}
      {currentView === VIEWS.VIEW6 && (
        <>
          <div>
            <div className="text-start ">
              <h1 className="display-1">
                <BsIcons.BsArrowLeftShort
                  className="app-color"
                  onClick={() => setCurrentView("view5")}
                />
              </h1>
            </div>
            <div>
              <ServiciosLista
                serviciosFiltrados={serviciosFiltrados}
                handleChangeServicios={handleChangeServicios}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default Agenda;
