const horario = [];
let top = 0;

for (let hora = 8; hora <= 23; hora++) {
 // const minutos = ["00", "30"];
  const minutos = ["00", "15", "30", "45"];
  minutos.forEach((minuto) => {
    const id = hora - 7 + minutos.indexOf(minuto);
    horario.push({
      id,
      top,
      hora: hora.toString() + ":" + minuto,
    });
    top += 39;
  });
}

  export default horario