import { Route, Redirect, useLocation } from "react-router-dom";
import useAuth from "../auth/useAuth";
export default function PrivateRoute({ component: Component, ...rest }) {
  
  const {rootState} = useAuth();
  const location = useLocation();
  const {isAuth, showLogin} = rootState;
  // const user = [rootState.theUser][0];

 



    if(isAuth){      
      return(
            <>
            <Route {...rest}>                         
              <main >
                <Component />  
              </main>                        
            </Route>
            </>          
           )
    }else if(showLogin){
        return (
            <Redirect to={{ pathname: "/" , state: { from: location }}} />
          ) ;
    }

}