import useAuth from "../../auth/useAuth";
import apiConfig from "../../services/apiConfig";
import { NavLink } from "react-router-dom";
import * as BsIcons from 'react-icons/bs';


import React, {useState, useEffect} from 'react';
import axios from 'axios';


export default function Business() {
 
   const auth = useAuth();
   const user = JSON.parse(localStorage.getItem('user'));
   const [APIData, setAPIData] = useState([]); 
   const [isLoading, setLoading] = useState(true);

    useEffect(() => {
         axios.get(apiConfig+'business/list/index.php?id='+user.id_business_unit)
            .then((response) => { 
                     let datos = response.data;       
                     setAPIData(datos);                
            });
            
         

            setLoading(false);
    },[user.id_business_unit]); 

    return (
    	<>
      <div className="container d-none d-sm-none d-md-block pt-8"> </div> <div><br/><br/>
      {isLoading && (
        <div></div>                
      )}
      {!isLoading && (
        <div className="container ">
         <div className="text-start mb-3">
            <NavLink exact to="/config"  className="nav-link">
               <h1><BsIcons.BsArrowLeftShort /></h1>
            </NavLink>            
         </div>
              {APIData.map((data,index) => {
               return (
                  <div key={index}>
                    <div className="pt-1" key={data.id}>
                       <h5 className="mb-3 text-uppercase bg-light p-2 text-muted">Información Empresa</h5>
                    </div>
                    <div className="row mb-5 pt-3 text-muted">
                       <div className="col-md-6">
                          <div className="mb-3">
                             <h6>Nombre o Razón social</h6>
                             <div>
                                <h5>{data.name}</h5>
                             </div>
                             <hr/>
                          </div>
                       </div>
                       <div className="col-md-6">
                          <div className="mb-3">
                             <h6>Id Empresa</h6>
                             <div>
                                <h5>{data.code}</h5>
                             </div>
                             <hr/>
                          </div>
                       </div>
                    </div>                 
                     <div className="row g-3">
                       <div className="col-md-6">
                          <label className="form-label">Calle</label>
                          <div className="form-control">{data.address}</div>
                       </div>
                       <div className="col-md-2">
                          <label className="form-label">Numero</label>
                          <div className="form-control">{data.number}</div>
                       </div>
                       <div className="col-md-4 ">
                          <label className="form-label">Ciudad</label>
                          <div className="form-control">{data.location}</div>
                       </div>
                       <div className="col-md-4">
                          <label className="form-label">Provincia</label>
                          <div className="form-control">{data.state}</div>
                       </div>
                       <div className="col-md-2">
                          <label className="form-label">Codigo Postal</label>
                          <div className="form-control">{data.zip}</div>
                       </div>
                       <div className="col-md-6 mb-5">
                          <label className="form-label">Pais</label>
                          <div className="form-control">{data.country}</div>
                       </div>
                     </div>
                 <br/>
                  </div>
                  )}
               )}
        </div>
        )}
        </div>  
      </>
    )
}
