import React, { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { Table, Container, Button } from "react-bootstrap";
import axios from "axios";
import apiConfig from "../../services/apiConfig";
import useAuth from "../../auth/useAuth";
import moment from "moment";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const Report = () => {
  const auth = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));
  const day = moment().format("YYYY-MM-DD");
  const [data, setData] = useState([]);
  const [turnos, setTurnos] = useState(null);
  const [locales, setLocales] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [desde, setDesde] = useState(day);
  const [hasta, setHasta] = useState(day);
  const [local, setLocal] = useState("");
  const [estadoPago, setEstadoPago] = useState("");
  const [enviado, setEnviado] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  const [buttonText, setButtonText] = useState("Enviar enlace de pago");
  const [hideButton, setHideButton] = useState(false);

  const getData = () => {
    axios
      .get(apiConfig + "pay/list/?id=" + Number(user.id_business_office))
      .then((getData) => {
        setData(getData.data);
      });

    axios
      .get(apiConfig + "business/list/?id=" + Number(user.id_business_unit))
      .then((getData) => {
        setLocales(getData.data.data);
      });
  };

  useEffect(() => {
    getData(Number(user.id_business_office));
  }, [user.id_business_office]);

  const buscarTurnos = () => {
    if (desde && hasta && local) {
      setTurnos(null);
      axios
        .get(apiConfig + "pay/list/list.php", {
          params: {
            id: local,
            desde: desde,
            hasta: hasta,
            id_estado_pago: estadoPago,
          },
        })
        .then((response) => {
          let res = response.data.data;

          if (res.length > 0) {
            setTurnos(res);
          } else {
            window.alert("No se encontraron registros");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }else{
      window.alert("Debes completar los campos");
    }
  };

  const colorMap = {
    1: "#fa5c7c", // "solicitado"
    2: "#0acf97", // "reservado"
  };

  function notificar(a) {
    setButtonActive(true);
    setButtonText("");

    setTimeout(() => {
      setHideButton(true);
      // document.body.style.backgroundColor = '#00a650';
    }, 8000);

    a.map((registro) => {
      const phoneNumber = registro.telefono;
      const message = `Hola, ¿cómo estás? Quiero recordarte tu turno de ${
        registro.tratamiento
      } del día ${moment(registro.fecha).format("dddd D/MM/YYYY")} a las ${
        registro.hora
      }. Se encuetra pendiente de pago, puedes abonar a traves del siguiente enlace. https://app.cronogram.com.ar/back/pay/?id=${
        registro.codigo
      } ¡Gracias!`;

      const whatsappLink = `https://api.cronogram.com.ar/?numero=${phoneNumber}&token=valentina&mensaje=${encodeURIComponent(
        message
      )}`;

      function enviar() {
        axios
          .get(whatsappLink)
          .then((res) => {
            let data = res.data;

            if (Number(data.success) === 1) {
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }

      enviar();
    });
  }

  useEffect(() => {
    setButtonActive(false);
    setButtonText("Recordatorio Turno");
    setHideButton(false);
  }, [filtro]);

  function viewBuscador() {
    setTurnos(null);
    setLocal(null);
    setEstadoPago(null);
  }
  return (
    <Container>
      {!turnos ? (
        <>
          <div className="text-start">
            <NavLink exact to={"/calendario"}>
              <h1 className="display-1">
                <BsIcons.BsArrowLeftShort className="app-color" />
              </h1>
            </NavLink>
          </div>
          <div className="card shadow mb-3" style={{ background: "#736CED" }}>
            <div className="card-body">
              <div className="text-center text-white">Reporte de Ventas</div>
            </div>
          </div>
          <Row>
            <Col md={4} sm={12}>
              <FloatingLabel
                controlId="floatingActual"
                label="Local"
                className="mb-3"
              >
                <select
                  className="form-select"
                  name="id_business_office"
                  onChange={(e) => setLocal(e.target.value)}
                  required
                >
                  <option selected></option>
                  {locales &&
                    locales.map((data, index) => (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))}
                </select>
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingFecha"
                label="Desde"
                className="mb-3"
              >
                <Form.Control
                  type="date"
                  name="desde"
                  value={desde && desde}
                  onChange={(e) => setDesde(e.target.value)}
                  required
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingFecha"
                label="Hasta"
                className="mb-3"
              >
                <Form.Control
                  type="date"
                  name="hasta"
                  min={desde}
                  value={hasta && hasta}
                  onChange={(e) => setHasta(e.target.value)}
                  required
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingActul"
                label="Estado de Pago"
                className="mb-3"
              >
                <select
                  className="form-select"
                  name="id_estado_pago"
                  onChange={(e) => setEstadoPago(e.target.value)}
                  required
                >
                  <option selected></option>
                  <option value={1}>Pendiente</option>
                  <option value={2}>Pagado</option>
                  <option value={3}>Todo</option>
                </select>
              </FloatingLabel>
              <div className="d-grid gap-2 pt-5">
                <Button variant="primary" onClick={buscarTurnos}>
                  Buscar
                </Button>
              </div>
            </Col>
            <Col md={8} sm={12}></Col>
          </Row>
        </>
      ) : (
        <Row>
          <div className="text-start">
            <h1 className="display-1">
              <BsIcons.BsArrowLeftShort
                className="app-color"
                onClick={() => viewBuscador()}
              />
            </h1>
          </div>

          <div>
            {locales &&
              locales
                .filter((x) => x.id === local)
                .map((data, index) => (
                  <p key={index}>
                    {data.name} del {moment(desde).format("DD/MM/YYYY")} Al{" "}
                    {moment(hasta).format("DD/MM/YYYY")}
                  </p>
                ))}
          </div>
          <div className="pt-2 mb-5">
            <div className="input-group ">
              <input
                placeholder="Filtro"
                className="form-control form-control-sm"
                type="text"
                onChange={(e) => setFiltro(e.target.value)}
              />
              <span className="input-group-text" id="basic-addon2">
                <BsIcons.BsSearch />
              </span>
            </div>
          </div>
          <div className="text-end mb-3">
            {!hideButton ? (
              <button
                id="color-button"
                className={buttonActive ? "active" : ""}
                onClick={() => notificar(turnos)}
              >
                {buttonText}
              </button>
            ) : (
              <button id="color-button-exito">
                <BsIcons.BsSendCheck />
              </button>
            )}
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Código</th>
                <th>Concepto</th>
                <th>Monto</th>
              </tr>
            </thead>
            <tbody>
              {turnos &&
                turnos
                  .filter(
                    (datos) =>
                      datos.codigo
                        .toLocaleLowerCase()
                        .includes(filtro.toLocaleLowerCase()) ||
                      datos.tratamiento
                        .toLocaleLowerCase()
                        .includes(filtro.toLocaleLowerCase()) ||
                      datos.estado_pago
                        .toLocaleLowerCase()
                        .includes(filtro.toLocaleLowerCase()) ||
                      datos.fecha
                        .toLocaleLowerCase()
                        .includes(filtro.toLocaleLowerCase())
                  )
                  .map((a, i) => (
                    <tr key={i}>
                      <td>#{a.codigo}</td>
                      <td>
                        <small>{a.tratamiento}</small>
                        <br />
                        <small>
                          {moment(a.fecha).format("DD-MM-YYYY")} {a.hora}
                        </small>
                      </td>
                      <td>
                        <small>
                          {Number(a.precio).toLocaleString("es-AR", {
                            style: "currency",
                            currency: "ARS",
                            minimumFractionDigits: 2,
                          })}
                        </small>
                        <br />

                        <small
                          style={{
                            background:
                              colorMap[Number(a.id_estado_pago)] || "green",
                            color: "white",
                            borderRadius: "3px",
                            padding: "5px",
                          }}
                        >
                          <span>{a.estado_pago}</span>
                        </small>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </Row>
      )}
    </Container>
  );
};

export default Report;
