import React from 'react';
import Terminos from './Terminos';
import { NavLink } from "react-router-dom";
import * as BsIcons from 'react-icons/bs';

function Terminosycondiciones() {
  return (
    <div>
        <div className="text-start">
        <NavLink exact to={"/calendario"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <h1><strong>Términos y condiciones</strong></h1>
      <Terminos />
    </div>
  )
}

export default Terminosycondiciones
