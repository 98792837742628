import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import { NavLink } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import apiConfig from "../../services/apiConfig";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import useAuth from "../../auth/useAuth";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

function WebUpdate() {
  const auth = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));
  const [activo,setActivo]= useState(false);
  const [datos,setDatos] =useState([]);
  
  const [registro, setRegistro] = useState({
    nombre: "",
    logo: "",
    background: "",
    ubicacion: "",
    telefono: "",
    email: "",
    descripcion: "",
    instagram: "",
    facebook: "",
    latitud:"",
    longitud:"",
    id_business_unit: user.id_business_unit,
    dias: [
      {
        id: 1,
        dia_semana: "Lunes",
        horario_apertura: "",
        horario_cierre: "",
        estado: 0,
      },
      {
        id: 2,
        dia_semana: "Martes",
        horario_apertura: "",
        horario_cierre: "",
        estado: 0,
      },
      {
        id: 3,
        dia_semana: "Miercoles",
        horario_apertura: "",
        horario_cierre: "",
        estado: 0,
      },
      {
        id: 4,
        dia_semana: "Jueves",
        horario_apertura: "",
        horario_cierre: "",
        estado: 0,
      },
      {
        id: 5,
        dia_semana: "Viernes",
        horario_apertura: "",
        horario_cierre: "",
        estado: 0,
      },
      {
        id: 6,
        dia_semana: "Sabado",
        horario_apertura: "",
        horario_cierre: "",
        estado: 0,
      },
      {
        id: 7,
        dia_semana: "Domingo",
        horario_apertura: "",
        horario_cierre: "",
        estado: 0,
      },
    ]
    
  });
  const guardarRegistro = (e) => {
    e.preventDefault()
    axios.post(apiConfig+'web_cliente/create/add.php', registro)
      .then(response => {
        let res = response.data
        if(res.success === 1){
          setActivo(true);
          window.alert('Se guardo correctamente')
        }else{
          window.alert('Ha ocurrido un error intenta más tarde')
        }
      })
      .catch(error => {
       
        console.error(error);
      });

  };

  const handleChange = (e) => {
    setRegistro({ ...registro, [e.target.name]: e.target.value });
  };

  const editarDia = (idDia, nuevaInformacion) => {
    setRegistro(prevRegistro => {
      // Hacemos una copia del estado actual
      const nuevoRegistro = { ...prevRegistro };
      
      // Buscamos el día con el id proporcionado
      const diaModificado = nuevoRegistro.dias.find(dia => Number(dia.id) === Number(nuevaInformacion.id));
      
      if (diaModificado) {
        // Actualizamos la información para el día encontrado
        diaModificado.horario_apertura = nuevaInformacion.horario_apertura;
        diaModificado.horario_cierre = nuevaInformacion.horario_cierre;
        diaModificado.estado = Number(idDia);
      }

      // Retornamos el nuevo estado actualizado
      return nuevoRegistro;
    });
  };

  const updateSchedule = (idDia, horario_apertura, horario_cierre, estado) => {
    setRegistro(prevRegistro => {
      // Hacemos una copia del estado actual
      const nuevoRegistro = { ...prevRegistro };
      
      // Buscamos el día con el id proporcionado
      const diaModificado = nuevoRegistro.dias.find(dia => Number(dia.id) === Number(idDia));
      
      if (diaModificado) {
        // Actualizamos la información para el día encontrado
        diaModificado.horario_apertura = horario_apertura;
        diaModificado.horario_cierre = horario_cierre;
        diaModificado.estado = Number(estado);
      }

      // Retornamos el nuevo estado actualizado
      return nuevoRegistro;
    });
  };

  function getData(){
    axios.get(apiConfig + 'web_cliente/list/?id=' + Number(user.id_business_unit))
    .then((getData) => {
      const res = getData.data;
     
      if(res.length > 0){
        setRegistro({
          nombre: res[0].nombre,
          logo: res[0].logo,
          background: res[0].background,
          ubicacion: res[0].ubicacion,
          telefono: res[0].telefono,
          email: res[0].email,
          descripcion: res[0].descripcion,
          instagram: res[0].instagram,
          facebook: res[0].facebook,
          latitud: res[0].latitud,
          longitud: res[0].longitud,
          id_business_unit: res[0].id_business_unit,
          dias:JSON.parse(res[0].dias)       
        }) 
      }
       
          
    });
  }
  
  useEffect(() => {  
      getData();  
  }, [user.id_business_unit]);






  const handleImage1Change = (e) => {
    const file = e.target.files[0];
    validateAndSetImage(file, 'logo', 600, 600);
  };

  const handleImage2Change = (e) => {
    const file = e.target.files[0];
    validateAndSetImage(file, 1200, 240);
  };

  const validateAndSetImage = (file, expectedWidth, expectedHeight) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;

      img.onload = function () {
        const width = img.width;
        const height = img.height;

        if (file.type === "image/png" ) {
          if(width < 510){
            const reader = new FileReader();
            reader.onload = () => {
              const base64Image = reader.result;
              setRegistro({ ...registro, logo: base64Image });
            };
            reader.readAsDataURL(file);
          }else{
            const reader = new FileReader();
            reader.onload = () => {
              const base64Image = reader.result;
              setRegistro({ ...registro, background: base64Image });
            };
            reader.readAsDataURL(file);
          }          
        } else {
          alert(`Por favor, seleccione una imagen PNG de ${' '}${expectedWidth}x${expectedHeight}px.`);
        }
      };
    };

    reader.readAsDataURL(file);
  };



  return (
     
				  <Container>
            <div className="text-start">
        <NavLink exact to={"/config"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#736CED" }}>
        <div className="card-body">
          <div className="text-center text-white">Tienda Web</div>
        </div>
      </div>
      <div className="mb-3">
              <h3>Configuración</h3>
            </div>
          <Row>
            <Col sm={12} md={6}>
            <Row>
            <Col  sm={12} md={6}>              
              <FloatingLabel
                controlId={"nombre"}
                className="mb-3"
                label="Nombre  *"
              >
                <Form.Control
                  type="text"
                  name="nombre"
                  value={registro && registro.nombre}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col sm={12} md={6}>
              <FloatingLabel
                controlId={"Dirección"}
                className="mb-3"
                label="Dirección  *"
              >
                <Form.Control
                  type="text"
                  name="ubicacion"
                  value={registro && registro.ubicacion}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md={12}>
              <FloatingLabel
                controlId={"Descripción"}
                className="mb-3"
                label="Descripción  *"
              >
                <Form.Control
                  as="textarea"
                  name="descripcion"
                  value={registro && registro.descripcion}
                  onChange={handleChange}
                  style={{ height: '100px' }}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col sm={12} md={6}>
              <FloatingLabel
                controlId={"Teléfono"}
                className="mb-3"
                label="Teléfono  *"
              >
                <Form.Control
                  type="text"
                  name="telefono"
                  value={registro && registro.telefono}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col sm={12} md={6}>
              <FloatingLabel
                controlId={"Email"}
                className="mb-3"
                label="Email  *"
              >
                <Form.Control
                  type="email"
                  name="email"
                  value={registro && registro.email}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col sm={12} md={6}>
              <FloatingLabel
                controlId={"instagram"}
                className="mb-3"
                label="Instagram  *"
              >
                <Form.Control
                  type="text"
                  name="instagram"
                  value={registro && registro.instagram}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col sm={12} md={6}>
              <FloatingLabel
                controlId={"facebook"}
                className="mb-3"
                label="Facebook  *"
              >
                <Form.Control
                  type="text"
                  name="facebook"
                  value={registro && registro.facebook}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col sm={12} md={6}>
              <FloatingLabel
                controlId={"latitud"}
                className="mb-3"
                label="Latitud  *"
              >
                <Form.Control
                  type="text"
                  name="latitud"
                  value={registro && registro.latitud}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col sm={12} md={6}>
              <FloatingLabel
                controlId={"longitud"}
                className="mb-3"
                label="Longitud  *"
              >
                <Form.Control
                  type="text"
                  name="longitud"
                  value={registro && registro.longitud}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col sm={12} md={6}>
              {registro.logo.length > 0 ?
              <><img src={registro.logo} className="img-fluid" alt="Logo" width={'90px'} onClick={()=> setRegistro({ ...registro, logo: '' })}/><br/></>
              :

              <FloatingLabel
                controlId={"logo"}
                className="mb-3"
                label="Logo 500px x 500px *"
              >
                <Form.Control
                  type="file"
                  name="logo"
                  accept="image/png"
                  onChange={handleImage1Change}
                  required
                />
              </FloatingLabel>
              }
            </Col>
            <Col sm={12} md={6}>
            {registro.background.length > 0 ? 
              <img src={registro.background} className="img-fluid" alt="Background" onClick={()=> setRegistro({ ...registro, background: '' })}/>
              :
              <FloatingLabel
                controlId={"background"}
                className="mb-3"
                label="Imagen Background 1200px x 240px *"
              >
                <Form.Control
                  type="file"
                  name="background"
                  accept="image/png"
                  onChange={handleImage2Change} 
                  required
                />
              </FloatingLabel>
              }
            </Col>
          </Row><br/>
          </Col>
            <Col sm={12} md={6}>
            {registro && registro.dias.map((diax, index) => (
            <Row key={index} className="mb-1">
              <Col>
                <FloatingLabel
                  controlId={index + "dia"}
                  className="mb-3"
                  label="Dia"
                >
                  <Form.Control
                    type="tex"
                    name="dias"
                    defaultValue={(diax.dia_semana).slice(0, 3)}
                    readOnly
                  />
                </FloatingLabel>
              </Col>

              <Col>
                <FloatingLabel
                  controlId={index + "apertura"}
                  className="mb-3"
                  label="Horario apertura  *"
                >
                  <Form.Control
                    type="time"
                    name="horario_apertura"
                    value={diax.horario_apertura}
                    onChange={(e) =>
                      updateSchedule(diax.id, e.target.value, diax.horario_cierre, diax.estado)
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId={index + "cierre"}
                  className="mb-3"
                  label="Horario cierre  *"
                >
                  <Form.Control
                    type="time"
                    name="horario_cierre"
                    value={diax.horario_cierre}
                    onChange={(e) =>
                      updateSchedule(diax.id, diax.horario_apertura, e.target.value, diax.estado)
                    }
                  />
                </FloatingLabel>
              </Col>
              
              <Col>
                <div className="pt-3">
				{Number(diax.estado) === 1 ?	
				<BsIcons.BsToggleOn  style={{fontSize: '2rem', color:'#736CED'}} onClick={()=> editarDia('0' , diax)}/>
				:
				<BsIcons.BsToggleOff style={{fontSize: '2rem', color:'#736CED'}} onClick={()=> editarDia ('1', diax)}/>
				}
				</div>
              </Col>
            </Row>
          ))}
            </Col>
          </Row>
          <Row>
          <div className="d-grid gap-2 pt-5 col-6">
            <button
              type="submit"             
              className="btn app-button  text-white shadow"
              onClick={guardarRegistro}
            >
              Guardar
            </button>
          </div>
          {registro &&
          <div className="d-grid gap-2 pt-5 col-6">
             <NavLink exact to={"/website/1"}>
             <button   className="btn app-button  text-white shadow">
                    Preview
             </button>
        </NavLink>
          </div>
          }
          </Row><br/><br/>

        
		  </Container>                   
  );
};

export default WebUpdate;