import { NavLink } from 'react-router-dom';
import axios from 'axios';
import React, {useState,  useEffect} from 'react';
import apiConfig from "../../services/apiConfig";
import useAuth from "../../auth/useAuth";
import * as BsIcons from 'react-icons/bs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './styles/Config.css';
import { DotWave } from '@uiball/loaders'



function Config() {
 const [users,setUsers] = useState([]);
 const [prestadores,setPrestadores] = useState([]);
 const [clientes,setClientes] = useState([]);
 const [local,setLocal] = useState([]);
 const [website, setWebsite] = useState([]);
 const [servicios, setServicios] = useState([]);
 const [medios, setMedios] = useState([])
 const auth = useAuth();
 const user = JSON.parse(localStorage.getItem('user'));
 const firstFiveItems = users.slice(0, 5);
 const totalItemCount = users.length;
 const firstFiveLocal = local.slice(0, 5);
 const totalLocalCount = local.length;
 const firstFivePres = prestadores.slice(0, 5);
 const totalPresCount = prestadores.length;
 const firstFiveClientes = clientes.slice(0, 5);
 const totalClientesCount = clientes.length;
 const firstFiveServicios = servicios.slice(0, 5);
 const totalServiciosCount = servicios.length;
 const colores = [ '#ffc700', '#3e96fe','#eb3f69','#50cd89','#736ced'];

 useEffect(() => {      
  axios.get(apiConfig+'profile/list/?id='+Number(user.id_business_unit))
  .then((getData) => {
      setUsers(getData.data);                
  });
  axios.get(apiConfig+'business/list/?id='+Number(user.id_business_unit))
  .then((getData) => {
      setLocal(getData.data.data);                
  });
  axios.get(apiConfig+'prestadores/list/?id='+Number(user.id_business_unit))
  .then((getData) => {
      setPrestadores(getData.data);                
  });
  axios.get(apiConfig+'customer/list/?id='+Number(user.id_business_unit))
  .then((getData) => {
      setClientes(getData.data);                
  });
  axios.get(apiConfig+'service/list/all.php?id='+Number(user.id_business_unit))
  .then((getData) => {
    setServicios(getData.data);                
  });
  axios
  .get(`${apiConfig}pay/integracionkey.php?id=${user.id_business_unit}`)
  .then((res) => {
    let { data } = res;
    if(Number(data.success) === 1){
      setMedios(data.data)      					
    }
  });
  axios.get(apiConfig + 'web_cliente/list/?id=' + Number(user.id_business_unit))
    .then((getData) => {
      let  data  = getData.data;
      if(data.length > 0){
        setWebsite(data)      					
      }
    });
 },[user.id_business_unit]);

  return (
      <div className='container' >
        <div className='text-start'>
          <NavLink exact to={'/calendario'} >
            <h1 className='display-1'><BsIcons.BsArrowLeftShort className="app-color" /></h1>
          </NavLink>
        </div>
        <div className='card shadow mb-3' style={{ background: "#736CED" }}>
            <div className='card-body'>
              <div className='text-center text-white'>
                  Panel de administración
              </div>
            </div>  
        </div>
        <div className='row '>
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/profile'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{users ? users.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Usuarios</span>
                  </div>                  
                  <div  className="symbol-group symbol-hover flex-nowrap pt-3">
                    {users ? firstFiveItems.map((data,index)=>(
                    <OverlayTrigger  key={index} overlay={<Tooltip id="tooltip-disabled">{data.name+', '+data.last_name}</Tooltip>}>
                      <div className="symbol symbol-35px symbol-circle" >
                        <img alt={data.name} src={apiConfig+'profile/image/'+data.avatar_url} />
                      </div>
                    </OverlayTrigger>
                         )) : 
                         <DotWave 
                            size={47}
                            speed={1} 
                            color="black" 
                            />
                    }
                      {totalItemCount > 5 && (
                        <div  className="symbol symbol-35px symbol-circle" >
                           +{totalItemCount - 5}
                        </div>         
                      )}        
                  </div>
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/locales'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{local ? local.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Locales</span>
                  </div>                  
                  <div  className="symbol-group symbol-hover flex-nowrap pt-3">
                    {local ? firstFiveLocal.map((data,index)=>(
                    <OverlayTrigger  key={index} overlay={<Tooltip id="tooltip-disabled">{data.name}</Tooltip>}>
                      <div className="symbol symbol-35px symbol-circle" >
                      <div style={{width:'35px', height: '35px',background: colores[index % colores.length],color:'white',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {index+1}
                        </div> 
                      </div>
                    </OverlayTrigger>
                         )) : 
                         <DotWave 
                            size={47}
                            speed={1} 
                            color="black" 
                            />
                    }
                      {totalLocalCount > 5 && (
                        <div  className="symbol symbol-35px symbol-circle" >
                           +{totalLocalCount - 5}
                        </div>         
                      )}        
                  </div>
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>          
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/prestadores'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{prestadores ? prestadores.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Prestadores</span>
                  </div>                  
                  <div  className="symbol-group symbol-hover flex-nowrap pt-3">
                    {prestadores ? firstFivePres.map((data,index)=>(
                    <OverlayTrigger  key={index} overlay={<Tooltip id="tooltip-disabled">{data.nombre+', '+data.apellido}</Tooltip>}>
                      <div className="symbol symbol-35px symbol-circle" >
                      <div style={{width:'35px', height: '35px',background: colores[index % colores.length],color:'white',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {data.nombre.substr(0,1)}{data.apellido.substr(0,1)} 
                        </div> 
                      </div>
                    </OverlayTrigger>
                         )) : 
                         <DotWave 
                            size={47}
                            speed={1} 
                            color="black" 
                            />
                    }
                      {totalPresCount > 5 && (
                        <div  className="symbol symbol-35px symbol-circle" >
                           +{totalPresCount - 5}
                        </div>         
                      )}        
                  </div>
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>
          <div className='col-md-6 col-sm-12 mb-3'>
          <NavLink exact to={'/config/categoria'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{servicios ? servicios.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Servicios</span>
                  </div>                  
                  <div  className="symbol-group symbol-hover flex-nowrap pt-3">
                    {servicios ? firstFiveServicios.map((data,index)=>(
                    <OverlayTrigger  key={index} overlay={<Tooltip id="tooltip-disabled">{index+1}</Tooltip>}>
                      <div className="symbol symbol-35px symbol-circle" >
                        <div style={{width:'35px', height: '35px',background: colores[index % colores.length],color:'white',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {index+1} 
                        </div>                        
                      </div>
                    </OverlayTrigger>
                         )) : 
                         <DotWave 
                            size={47}
                            speed={1} 
                            color="black" 
                            />
                    }
                      {totalServiciosCount > 5 && (
                        <div  className="symbol symbol-35px symbol-circle" >
                          <div style={{width:'35px', height: '35px',background: '#f9f9f9', color:'#c7c6cd',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                           +{totalServiciosCount - 5}
                           </div>
                        </div>         
                      )}        
                  </div>
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/customer'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{clientes ? clientes.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Clientes</span>
                  </div>                  
                  <div  className="symbol-group symbol-hover flex-nowrap pt-3">
                    {clientes ? firstFiveClientes.map((data,index)=>(
                    <OverlayTrigger  key={index} overlay={<Tooltip id="tooltip-disabled">{data.nombre+', '+data.apellido}</Tooltip>}>
                      <div className="symbol symbol-35px symbol-circle" >
                        <div style={{width:'35px', height: '35px',background: colores[index % colores.length],color:'white',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {data.nombre.substr(0,1)}{data.apellido.substr(0,1)} 
                        </div>                        
                      </div>
                    </OverlayTrigger>
                         )) : 
                         <DotWave 
                            size={47}
                            speed={1} 
                            color="black" 
                            />
                    }
                      {totalClientesCount > 5 && (
                        <div  className="symbol symbol-35px symbol-circle" >
                          <div style={{width:'35px', height: '35px',background: 'grey', color:'white',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                           +{totalClientesCount - 5}
                           </div>
                        </div>         
                      )}        
                  </div>
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/webUpdate'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{website ? website.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Tienda web</span>
                  </div>                  
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/medios'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                      <h3>{medios ? medios.length :  '0' }</h3>
                      <span style={{color:'#474747'}}>Medios de pago electrónicos</span>{medios && 
                      <div className='pt-1 text-primary'>
                        Mercado pago
                      </div>
                      }
                  </div>                  
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>
          <div className='col-md-6 col-sm-12  mb-3'>
          <NavLink exact to={'/config/mensajeria'} >
            <div className='card shadow'>
              <div className="card-body" style={{height: '10rem'}}>
                <div className='container'>
                  <div className='text-start'>
                     
                      <span style={{color:'#474747'}}>Mensajeria</span>
                  </div>                  
                </div>                                     
              </div>
            </div>
            </NavLink>            
          </div>     
        </div>
      </div>      
  )
}

export default Config

