import React, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import "./CalendarComponent.css";
import Agenda from "./Agenda";
import axios from "axios";
import apiConfig from "../../services/apiConfig";
import useAuth from "../../auth/useAuth";
import * as BsIcons from "react-icons/bs";
import imagenSVG from "../../components/profile/user-generico.png";
import { Container, Row, Col } from "react-bootstrap";
import Calendario from "./Calendario";
import DropdownSearch from "./DropdownSearch";
import HistorialCliente from "./HistorialCliente/HistorialCliente";
import Menu from "../../components/navbar/Menu";

const CalendarComponent = () => {
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(false);
  const auth = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));

  const [clientes, setClientes] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [dataTurnos, setDataTurnos] = useState([]);
  const [estados, setEstados] = useState([]);
  const [estadoPago, setEstadoPago] = useState([]);
  const [profesionales, setProfesionales] = useState([]);
  const [profesional, setProfesional] = useState([]);
  const [show, setShow] = useState(false);
  const [isSmartphone, setIsSmartphone] = useState(false);
  const totalItemCount = profesionales.length;
  useEffect(() => {
    const getData = (user) => {
      axios
        .get(
          apiConfig +
            "prestadores/list/agenda.php?id=" +
            Number(user.id_business_office)
        )
        .then((getData) => {
          setProfesionales(getData.data);
        });
      axios
        .get(apiConfig + "customer/list/?id=" + Number(user.id_business_unit))
        .then((getData) => {
          setClientes(getData.data);
        });
      axios
        .get(
          apiConfig +
            "category/list/categoriasActivas.php?id=" +
            Number(user.id_business_unit)
        )
        .then((getData) => {
          setCategoria(getData.data);
        });
      axios
        .get(
          apiConfig +
            "service/list/serviciosActivos.php?id=" +
            Number(user.id_business_unit)
        )
        .then((getData) => {
          setServicios(getData.data);
        });
      axios
        .get(apiConfig + "turnos/list/?id=" + Number(user.id_business_office))
        .then((getData) => {
          setDataTurnos(getData.data);
        });
      axios
        .get(
          apiConfig +
            "turnos/estados/estadoTurno.php?id=" +
            Number(user.id_business_office)
        )
        .then((getData) => {
          setEstados(getData.data);
        });
      axios
        .get(
          apiConfig +
            "turnos/estados/estadoPago.php?id=" +
            Number(user.id_business_office)
        )
        .then((getData) => {
          setEstadoPago(getData.data);
        });
    };

    getData(user);
    setShow(true);
  }, [date, user.id_business_office]);

  const getTurnos = () => {
    axios
      .get(apiConfig + "turnos/list/?id=" + Number(user.id_business_office))
      .then((getData) => {
        setDataTurnos(getData.data);
      });
  };
  const getClientes = () => {
    axios
      .get(apiConfig + "customer/list/?id=" + Number(user.id_business_office))
      .then((getData) => {
        setClientes(getData.data);
      });
  };

  const actualizarTurnos = (item) => {
    setDataTurnos(item);
  };
  const [filtro, setFiltro] = useState("");
  const [showClientes, setShowClientes] = useState(false);
  const [cliente, setCliente] = useState([]);
  const [viewMobil, setViewMobil] = useState(false);

  const handleFiltro = (e) => {
    setFiltro(e);
  };
  const buscarCliente = () => {
    setShowClientes(true);
  };
  const buscarPrestador = () => {
    setShowClientes(false);
    if (isSmartphone) {
      setViewMobil(false);
    }
  };
  const seleccionCliente = (e) => {
    setCliente(e);
    if (isSmartphone) {
      setViewMobil(true);
    }
  };
  const cleanCliente = () => {
    setCliente("");
  };
  const handleDateChange = (value) => {
    setDate(value);
  };
  function handleBoxChange(item) {
    setProfesional(item);
    !view && setView(true);
  }
  const handleViewMobil = () => {
    setShow(true);
  };
  const [abrir, setAbrir] = useState(false);

  const handleShowMenu = () => {
    setAbrir(true);
  };
  const handleCloseMenu = () => {
    setAbrir(false);
  };

  return (
    <>
      {show ? (
        <>
        <Container fluid style={{ height: "100vh" }}>
            <Row>
              <Col
                sm={12}
                md={4}
                style={{
                  borderRight: "solid 1px #ededed",
                  
                }}
              >
            <div
            className="row"
            style={{
              backgroundColor: 'rgb(115, 108, 237)',
              color: 'white',
              borderLeft:' 0.5px solid rgb(171, 165, 165)',
            }}
          >
            <div
            style={{
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            >
            <span style={{ fontSize: "1.5rem" }}>Cronogram</span>

              <BsIcons.BsThreeDotsVertical
                style={{ fontSize: "1.5rem" }}
                onClick={handleShowMenu}
              />
          </div>

          </div><br/>
                  <Calendario
                    fecha={date}
                    turnos={dataTurnos}
                    profesional={profesional}
                    actualizarFechas={handleDateChange}
                  /><br/>

                  <Container className="pt-1 mb-2">
                    <DropdownSearch
                      filtro={handleFiltro}
                      buscarCliente={buscarCliente}
                      buscarPrestador={buscarPrestador}
                      cleanCliente={cleanCliente}
                    />
                  </Container>

                  <div>
                    <div className="profile-scrollable">
                      {!showClientes ? (
                        <>
                          {profesionales &&
                            profesionales
                              .filter(
                                (datos) =>
                                  datos.nombre
                                    .toLocaleLowerCase()
                                    .includes(filtro.toLocaleLowerCase()) ||
                                  datos.apellido
                                    .toLocaleLowerCase()
                                    .includes(filtro.toLocaleLowerCase()) ||
                                  datos.telefono
                                    .toLocaleLowerCase()
                                    .includes(filtro.toLocaleLowerCase())
                              )
                              .map((data, index) => (
                                <div
                                  className={
                                    profesional.id === data.id
                                      ? "active-profile"
                                      : "profile-active"
                                  }
                                  onClick={() => handleBoxChange(data)}
                                  key={index}
                                >
                                  <div className="symbol-group  flex-nowrap ">
                                    <div className="profile-pic">
                                      <img
                                        alt={data.nombre}
                                        src={
                                          data.avatar_url === "no-avatar.png"
                                            ? imagenSVG
                                            : apiConfig +
                                              "prestadores/image/" +
                                              data.avatar_url
                                        }
                                      />
                                    </div>

                                    <div
                                      className="profile_data"
                                      style={{
                                        borderTop: "1px solid #8696a026",
                                        flex: 11,
                                      }}
                                    >
                                      <div className="pt-2">
                                        {data.nombre + ", " + data.apellido}
                                      </div>
                                      <div
                                        className="mb-2"
                                        style={{ color: "#8696a0" }}
                                      >
                                        Prestador de servicios
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                        </>
                      ) : (
                        <>
                          {clientes &&
                            clientes
                              .filter(
                                (datos) =>
                                  datos.nombre
                                    .toLocaleLowerCase()
                                    .includes(filtro.toLocaleLowerCase()) ||
                                  datos.apellido
                                    .toLocaleLowerCase()
                                    .includes(filtro.toLocaleLowerCase()) ||
                                  datos.telefono
                                    .toLocaleLowerCase()
                                    .includes(filtro.toLocaleLowerCase())
                              )
                              .map((data, index) => (
                                <div
                                  className="profile-active"
                                  onClick={() => seleccionCliente(data)}
                                  key={index}
                                >
                                  <div className="symbol-group  flex-nowrap ">
                                    <div className="profile-pic">
                                      <img
                                        alt={data.nombre}
                                        src={
                                          data.img === ""
                                            ? imagenSVG
                                            : apiConfig +
                                              "customer/image/" +
                                              data.avatar_url
                                        }
                                      />
                                    </div>

                                    <div
                                      className="profile_data"
                                      style={{
                                        borderTop: "1px solid #8696a026",
                                        flex: 11,
                                      }}
                                    >
                                      <div className="pt-2">
                                        {data.seudonimo}
                                      </div>
                                      <div
                                        className="mb-2"
                                        style={{ color: "#8696a0" }}
                                      >
                                        Cliente
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                        </>
                      )}
                    </div>
                    {totalItemCount > 5 && (
                      <div className="symbol symbol-35px symbol-circle">
                        +{totalItemCount - 5}
                      </div>
                    )}
                  </div>
               
              </Col>

              {!showClientes ? (
                <div className="d-none d-ms-none d-md-block col-md-8 col-sm-12">
                  {view && (
                    <Agenda
                      fecha={date}
                      profesional={profesional}
                      profesionales={profesionales}
                      clientes={clientes}
                      categoria={categoria}
                      servicios={servicios}
                      turnos={dataTurnos}
                      estados={estados}
                      estadoPago={estadoPago}
                      getTurnos={getTurnos}
                      getClientes={getClientes}
                      actualizarFechas={handleDateChange}
                      actualizarTurnos={actualizarTurnos}
                      handleViewMobil={handleViewMobil}
                      style={{ marginLeft: "0px", marginRight: "0px" }}
                    />
                  )}
                </div>
              ) : (
                <div
                className="d-none d-ms-none d-md-block col-md-8 col-sm-12"
                  style={{ paddingTop: isSmartphone ? "70px" : "0xp" }}
                  
                >
                  {cliente && (
                    <HistorialCliente
                      cliente={cliente}
                      filtro={handleFiltro}
                      buscarPrestador={buscarPrestador}
                      cleanCliente={cleanCliente}
                    />
                  )}
                </div>
              )}
            </Row>
            {view && 
            <div
            className="row d-sm-block d-md-none"
            style={{
              background: 'rgb(115, 108, 237)',
              color: 'rgb(255, 255, 255)',            
              position:'fixed',
              bottom:'0px',
              width: '100%'
            }}
            onClick={()=>setShow(false) }
          >
            <div
            style={{
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              alignContent:'center',
              justifyContent: 'center',
              gap:20
            }}
            >

              
            <span > Ver</span>   
            
                

          </div>
          </div>
          }
          </Container>
          <Menu abrir={abrir} handleCloseMenu={handleCloseMenu} />

        </>
      ) : (
        <Container>
          {!showClientes ? (
                <div className="d-ms-block d-md-none  col-sm-12">
                  {view && (
                    <Agenda
                      fecha={date}
                      profesional={profesional}
                      profesionales={profesionales}
                      clientes={clientes}
                      categoria={categoria}
                      servicios={servicios}
                      turnos={dataTurnos}
                      estados={estados}
                      estadoPago={estadoPago}
                      getTurnos={getTurnos}
                      getClientes={getClientes}
                      actualizarFechas={handleDateChange}
                      actualizarTurnos={actualizarTurnos}
                      handleViewMobil={handleViewMobil}
                      style={{ marginLeft: "0px", marginRight: "0px" }}
                    />
                  )}
                </div>
              ) : (
                <div className="d-ms-block d-md-none  col-sm-12">
                  {cliente && (
                    <HistorialCliente
                      cliente={cliente}
                      filtro={handleFiltro}
                      buscarPrestador={buscarPrestador}
                      cleanCliente={cleanCliente}
                      handleViewMobil={handleViewMobil}
                    />
                  )}
                </div>
              )}

        </Container>
      )}
    </>
  );
};

export default CalendarComponent;
