import axios from 'axios';
import React, {useRef,useState,  useEffect} from 'react';
import apiConfig from "../../../services/apiConfig";
import * as BsIcons from 'react-icons/bs';
import useAuth from "../../../auth/useAuth";
import Modal from 'react-bootstrap/Modal';
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import { dataURLtoFile } from "./utils/dataURLtoFile";
import cam from "./utils/camara.png";
import generico from "./utils/user-generico.png";

function Imagen(props) {
    const auth = useAuth();
    const user = JSON.parse(localStorage.getItem('user'));  
    const avatar = apiConfig+'customer/image/'+props.cliente.avatar_url
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false); 
    const inputRef = useRef('');   
    const triggerFileSelectPopup = () => inputRef.current.click();
    const [image, setImage] = useState(avatar);
    const [file,setFile]= useState(null);
    const [message, setMessage]=useState(null);
    const [preview, setPreview] = useState(avatar);
    const [croppedArea, setCroppedArea] = React.useState(null);
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);



    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
      setCroppedArea(croppedAreaPixels);
    };


   const onDownload = () => {
      generateDownload(image, croppedArea);
   };


  
const createImage = (url) =>
   new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
   });

function getRadianAngle(degreeValue) {
   return (degreeValue * Math.PI) / 180;
}

const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0)=> {
   const image = await createImage(imageSrc);
   const canvas = document.createElement("canvas");
   const ctx = canvas.getContext("2d");

   const maxSize = Math.max(image.width, image.height);
   const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

   // set each dimensions to double largest dimension to allow for a safe area for the
   // image to rotate in without being clipped by canvas context
   canvas.width = safeArea;
   canvas.height = safeArea;

   // translate canvas context to a central location on image to allow rotating around the center.
   ctx.translate(safeArea / 2, safeArea / 2);
   ctx.rotate(getRadianAngle(rotation));
   ctx.translate(-safeArea / 2, -safeArea / 2);

   // draw rotated image and store data.
   ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
   );

   const data = ctx.getImageData(0, 0, safeArea, safeArea);

   // set canvas width to final desired crop size - this will clear existing context
   canvas.width = pixelCrop.width;
   canvas.height = pixelCrop.height;

   // paste generated rotate image with correct offsets for x,y crop values.
   ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
   );

   // As Base64 string
   // return canvas.toDataURL("image/jpeg");
   return canvas;
}



   const generateDownload = async (imageSrc, crop) => {
   if (!crop || !imageSrc) {
      return;
      }
      const canvas = await getCroppedImg(imageSrc, crop);
      const canvasDataUrl = canvas.toDataURL("image/jpeg");
      const convertedUrlToFile = dataURLtoFile(
            canvasDataUrl,
            "cropped-image.jpeg"
         );
      setFile(convertedUrlToFile);      
      const reader = new FileReader();
         reader.readAsDataURL(convertedUrlToFile);
         reader.addEventListener("load", () => {
            setPreview(reader.result);
            setShow(false);            
         });
         
            
   };

   
   const createImageNew = () => { 
    if(file){       
    const formData1 = new FormData();
          formData1.append('file',file);
          formData1.append('id',props.cliente.id_cliente);        
    axios.post(apiConfig+`customer/add/image.php`, formData1)
    .then((res) => {
          let {data} = res;                           
          if(data.success && data.message){              
              if(data.success === 1){
                
              }
          }
        })
        .catch(function (res) {
          console.log(res)
        });
        
    }
};

useEffect(() => {
    createImageNew()
}, [file]);



const handleShowNew = () => {
  if(message){
    setMessage(null);
  }
  clearImg();      
  setShow(true)
};

const clearImg = () => {
    setImage(null);
    setPreview(null);
};

  return (
    <div className="text-center">
    <input type="file" name="image" id="image" accept="image/*" capture="camera" 
       onChange={(event) => { 
    const file = event.target.files[0];  ; 
    const reader = new FileReader();
    reader.onloadend = () => {
    setImage(reader.result);
    };
    reader.readAsDataURL(file);
    setShow(true)
    }} 
    ref={inputRef} style={{ display: "none" }}/>
        <img src={preview} alt="" className="rounded-circle account-user " onClick={triggerFileSelectPopup}/>
    <Modal show={show} size="lg" onHide={handleClose}>
       <Modal.Header closeButton>Imagen</Modal.Header>
       <Modal.Body>
          <div className='containers'>
             <div className='container-cropper'>
                <div className='cropper'>
                   <Cropper image={image} crop={crop} zoom={zoom} aspect={2/2} cropShape="round" showGrid={false} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
                </div>
                <div className='slider'>
                   <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) =>
                   setZoom(zoom)}/>
                </div>
             </div>
             <div className='container-buttons'>
                <div className="col-1"></div>
                <button className='col-3 btn app-button btn-lg' onClick={onDownload}>
                   <BsIcons.BsCheckLg />
                </button>
             </div>
          </div>
       </Modal.Body>
    </Modal>
 </div>
  )
}

export default Imagen
