import React, { useEffect, useState } from "react";
import { Navbar, Container, Row, Col} from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import apiConfig from "../../services/apiConfig";
import axios from "axios";
import CryptoJS from "crypto-js";
import * as BsIcons from "react-icons/bs";
import "./Website.css";

const WebsiteAgenda = () => {
  let { id } = useParams();
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(id), "clave_secreta");
  const info = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const [data, setData] = useState(null);

  function getData() {
    axios
      .get(apiConfig + "web_cliente/list/?id=" + Number(info.id_business_unit))
      .then((getData) => {
        const res = getData.data;

        if (res.length > 0) {
          setData({
            nombre: res[0].nombre,
            logo: res[0].logo,
            background: res[0].background,
            ubicacion: res[0].ubicacion,
            telefono: res[0].telefono,
            email: res[0].email,
            descripcion: res[0].descripcion,
            instagram: res[0].instagram,
            facebook: res[0].facebook,
            latitud: res[0].latitud,
            longitud: res[0].longitud,
            id_business_unit: res[0].id_business_unit,
            dias: JSON.parse(res[0].dias),
          });
        }
      });
  }

  useEffect(() => {
    getData();
  }, []);



  return (
    <>
      <div style={{ background: "#f5f5f5" }}>
        <Navbar className="bg-body-tertiary">
          <Container>
            <div className="btnCenter">
              <div className="d-none d-sm-none d-md-block text-end">
                <a
                  href={`https://api.whatsapp.com/send/?phone=%2B549${
                    data && data.telefono
                  }&text=Buen+día+quiero+información+sobre+cronogram&app_absent=0`}
                 
                >
                  <img
                    src="https://cronogram.com.ar/assets/logo-wasap.png"
                    className="shadow"
                    style={{ borderRadius: "50px 50px" }}
                    width={80}
                    height={80}
                    alt="Imagen"
                  />
                </a>
              </div>
              <div className="d-sm-block d-md-none text-end">
                <a
                  href={`https://api.whatsapp.com/send/?phone=%2B549${
                    data && data.telefono
                  }&text=Buen+día+quiero+información+sobre+cronogram&app_absent=0`}
                  
                >
                  <img
                    src="https://cronogram.com.ar/assets/logo-wasap.png"
                    className="shadow"
                    style={{ borderRadius: "50px 50px" }}
                    width={55}
                    height={55}
                    alt="Imagen"
                  />
                </a>
              </div>
            </div>
            <Navbar.Brand>
              <img
                className="navbar-logo-img"
                src={
                  data
                    ? data.logo
                    : apiConfig + "business/image/businessGeneric.svg"
                }
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text></Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container>
        <div className="text-start pt-5">
        <NavLink 
        exact
        to={
          "/website/" +
          encodeURIComponent(
            CryptoJS.AES.encrypt(
              JSON.stringify(info),
              "clave_secreta"
            ).toString()
          )
        }>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
          <div className="pt-3 mb-3 text-dark display-6">
            Servicios por categoria
          </div>
         
        </Container>
        <br />
        <div
          className="text-muted bg-dark "
          style={{ padding: "20px", minHeight: "200px", position: "relative" }}
        >
          <Container>
            <Row>
              <Col sm={6} md={3}>
                <div style={{ width: "50px", height: "50px" }}>
                  <img
                    className="img-fluid"
                    style={{ borderRadius: "25px" }}
                    src={data && data.logo}
                    alt="Logo"
                  />
                </div>
              </Col>
              <Col sm={6} md={3} className="text-start text-muted">
                <strong>Encuentranos</strong>
                <br />
                <br />
                <small>
                  <BsIcons.BsGeoAltFill /> {data && data.ubicacion}
                </small>
                <br />
                <small>
                  <BsIcons.BsFillTelephoneFill /> {data && data.telefono}
                </small>
              </Col>
              <Col sm={6} md={3} className="text-start text-muted">
                <strong>Redes sociales</strong>
                <br />
                <br />
                <a href={data && data.instagram}>
                  <small>
                    <BsIcons.BsInstagram /> Instagram
                  </small>
               </a>
                <br />
                <a href={data && data.facebook} >
                  <small>
                    <BsIcons.BsFacebook /> Facebook
                  </small>
              </a>
              </Col>
              <Col sm={6} md={3} className="text-start">
                <strong>Información</strong>
                <br />
                <br />
               
                  <small>Termino y condiciónes</small>
               
              </Col>
            </Row>
          </Container>
          <hr />
          <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
            <small className="text-muted">
              Copyright © <strong>Cronogram</strong> 2023
            </small>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsiteAgenda;
