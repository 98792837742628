import React from 'react'
import { NavLink } from "react-router-dom";
import * as BsIcons from 'react-icons/bs';

function Parametros() {
  return (
	<div className="container pt-5">
    	<div className="text-start pt-5">
            <NavLink exact to="/Home"  className="nav-link">
               <h1><BsIcons.BsArrowLeftShort /></h1>
            </NavLink>            
         </div>
		
	</div>
  )
}

export default Parametros

