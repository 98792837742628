import { useState } from "react";
import * as BsIcons from "react-icons/bs";
import { NavLink, useHistory } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import apiConfig from "../../../services/apiConfig";
import axios from "axios";
import useAuth from "../../../auth/useAuth";
import Localidades from "../../../services/Localidades/Localidades";
import { useEffect } from "react";

export default function CustomerAdd(props) {
  const auth = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));
  const apiUrl = `${apiConfig}customer/add/index.php`;
  const [locales, setLocales] = useState([]);
  const [agenda, setAgenda] = useState(props.agenda || null);
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    email: "",
    telefono: "",
    fecha_nac: "",
    direccion: "",
    ciudad: "",
    provincia: "",
    pais: "",
    codigo_postal: "",
    avatar_url: "",
    id_business_office: '',
	id_business_unit: user.id_business_unit,
  });

  useEffect(()=>{
	axios.get(apiConfig+'business/list/?id='+Number(user.id_business_unit))
	.then((getData) => {
		setLocales(getData.data.data);                
	});
  },[])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(apiUrl, formData)
      .then((res) => {
        let { data } = res;

        if (!agenda) {
          history.push("/config/customer/");
        } else {
          props.handleCloseC();
          props.getClientes();
        }
      })
      .catch(function (res) {
        console.log(res);
      });
  };

  return (
    <div className="container">
      {!agenda && (
        <>
          <div className="text-start">
            <NavLink exact to={"/config/customer"}>
              <h1 className="display-1">
                <BsIcons.BsArrowLeftShort className="app-color" />
              </h1>
            </NavLink>
          </div>
          <div className="card shadow mb-3" style={{ background: "#736CED" }}>
            <div className="card-body">
              <div className="text-center text-white">Nuevo Cliente</div>
            </div>
          </div>
        </>
      )}

      <div className="row pt-3">
        <div className="text-end text-muted mb-3">
          <small>* Campos obligatorios</small>
        </div>
        <form>
          <Row>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingCodigo"
                label="Nombre *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="nombre"
                  placeholder="Nombre"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingDescripcion"
                label="Apellido *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="apellido"
                  placeholder="Apellido"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId="floatingTotal"
                label="teléfono *"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  name="telefono"
                  placeholder="teléfono"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId="floatingDescripcion"
                label="Email"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="email"
                  onChange={handleChange}
                  
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId="floatingActual"
                label="Fecha de nacimiento"
                className="mb-3"
              >
                <Form.Control
                  type="date"
                  name="fecha_nac"
                  placeholder="Fecha de nacimiento"
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId="floatingTotal"
                label="Dirección "
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="direccion"
                  placeholder="Dirección"
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId="floatingProvincia"
                label="Provincia *"
                className="mb-3"
              >
                <select
                  type="text"
                  name="provincia"
                  className="form-control"
                  onChange={handleChange}
                  required
                >
                  <option value={0} style={{ color: "#888" }}></option>
                  {Localidades &&
                    Localidades.map((data, index) => {
                      return (
                        <option
                          key={index}
                          value={data.nombre}
                          style={{ color: "#888" }}
                        >
                          {data.nombre}
                        </option>
                      );
                    })}
                </select>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId="floatingLocalidad"
                label="Localidad *"
                className="mb-3"
              >
                <select
                  name="ciudad"
                  className="form-control"
                  onChange={handleChange}
                  required
                >
                  <option value="" style={{ color: "#888" }}></option>
                  {formData.provincia.length > 0 &&
                    Localidades.filter((x) =>
                      x.nombre.includes(formData.provincia)
                    ).map((data) => {
                      return data.ciudades.map((dat, index) => {
                        return (
                          <option key={index} value={dat.nombre}>
                            {dat.nombre}
                          </option>
                        );
                      });
                    })}
                </select>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                controlId="floatingTotal"
                label="Codígo postal"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="codigo_postal"
                  placeholder="Codígo postal"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId="floatingActual"
                label="País"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="pais"
                  placeholder="País"
                  value="Argentina"
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
          </Row>
		  <FloatingLabel
					controlId="floatingActual"
					label="Local"
					className="mb-3"
				>
				<select className="form-select"  name="id_business_office" onChange={handleChange}  required>
					<option selected></option>
					{locales && locales.map((data,index)=>(
					<option value={data.id} key={index}>{data.name}</option>
					))}
				</select> 
			</FloatingLabel>

          <div className="d-grid gap-2 pt-5">
            <button
              onClick={handleSubmit}
              className="btn app-button  text-white shadow"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
