import React, { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";

function DropdownSearch(props) {
  
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([
    "Cliente"
  ]);

  
  
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClick = () =>{
    setShow1(false);
    setShow2(true);
    setShow3(false);
    props.buscarPrestador()
    props.filtro('')
    props.cleanCliente()
  }
  const handleClick1 = () =>{
    setShow3(false);
    setShow2(false);
    setShow1(true);
    setSearchTerm("")
    props.buscarPrestador()
    props.filtro('')
    props.cleanCliente()
  }
  const handleClick2 = () =>{
    setShow1(false);
    setShow3(false);
    setShow2(true);
    setSearchTerm("")
    props.buscarPrestador()
    props.filtro('')
    props.cleanCliente()
  }

  const handleChange = (e) => {
    const  value = e.target.value;
    props.filtro(value)
   
  };

  const handleSelectOption = (option) => {
    setSearchTerm(option);    
    setShow2(false);
    setShow3(true);
    props.buscarCliente()
  };

  

  return (
    <>
      {show1 &&
      <div className="input-group mb-3">
      <span className="input-group-text" id="basic-addon1"><BsIcons.BsSearch onClick={handleClick}/></span>
      <input type="text" className="form-control" placeholder="Buscar prestador o cliente"  aria-describedby="basic-addon1" onClick={handleClick}/>
    </div>
      }
      {show2 &&
      <>
      <div  style={{
          width: "100%",          
          marginTop: "5px",
          padding: "0px",
          listStyleType: "none",
          backgroundColor: "#e8f0fe",
          border: "1px solid #e8f0fe",
          borderRadius: "5px 5px 0px 0px",
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}>
        
          
          <BsIcons.BsArrowLeftShort className="app-color" style={{flex: 1,fontSize: '1.5rem'}} onClick={handleClick1}/>
          <div style={{flex: 11}}>
            <input
              type="text"              
              onChange={handleChange}
              placeholder="Buscar..."
              className="form-control"
              autoFocus
            />
          </div>
        
      </div>
      {options.length > 0 && (
          <div style={{
            width: "100%", 
            padding: "20px",
            listStyleType: "none",
            backgroundColor: "#e8f0fe",
            border: "1px solid #e8f0fe",
            borderRadius: "0px 0px 5px 5px",
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '10px',
          }}>
            {options.map((option) => (
              <span
                key={option}
                onClick={() => handleSelectOption(option)}
                style={{
                  backgroundColor: "#736ced",
                  color: "#dcdefc",
                  width: "100px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "15px",
                  padding: "5px",
                  cursor: "pointer",
                }}
              >
                {option}
              </span>
            ))}
          </div>
        )}
        </>
      }
        {show3 &&
      <div
        style={{
          width: "100%",          
          marginTop: "5px",
          padding: "0",
          listStyleType: "none",
          backgroundColor: "#e8f0fe",
          border: "1px solid transparent",
          borderRadius: "5px",
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
            <BsIcons.BsArrowLeftShort className="app-color " style={{flex: 1,fontSize: '1.5rem'}}  onClick={handleClick2}/>
  
        <div style={{flex: 1}}>
          <span
            style={{
              backgroundColor: "#736ced",
              color: "#dcdefc",
              width: "100px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "15px",
              padding: "5px",
              cursor: "pointer",
              gap:'10px',
            }}
          >
            {searchTerm}
            <BsIcons.BsXLg className="text-white" style={{fontSize: '0.7rem'}} onClick={handleClick2}/>
          </span>
        </div>
        <div style={{flex: 9}}>
          <input
            type="text"
            onChange={handleChange}
            className="form-control"
            placeholder="Buscar..."
            autoFocus
            
          />
        </div>
        <BsIcons.BsXLg className="app-color" style={{flex: 1, fontSize: '1.5rem'}} onClick={handleClick1}/>
      </div>
      }
    </>
  );
}

export default DropdownSearch;
