import React, {useState, useEffect} from 'react';
import axios from "axios";
import apiConfig from "../../../../services/apiConfig";
import { Form, Row, Col, Button, FloatingLabel } from 'react-bootstrap';

const Update = (props) => {
    const [datoSeleccionado,setDatoSeleccionado] = useState([]);

	function updateUser(event){           
			event.preventDefault();
			axios.post(apiConfig+`customer/update/index.php`,datoSeleccionado)
			.then((res) => {
				let {data} = res;                  
				if(data.success && data.message){ 			  }
					props.getData(datoSeleccionado)
			})
			.catch(function (res) {
				
			});		
	}

	const handleChange = (e) => {        
		setDatoSeleccionado({...datoSeleccionado,[e.target.name]:e.target.value});		
	}

	useEffect(() => {
		setDatoSeleccionado(props.data)
	}, [props.data]);


	return  <div className="text-start">
	  <div className="col-12 pt-1">
		<Form onSubmit={updateUser}>
		<div className="mb-2">	
			<FloatingLabel label="Seudonimo">			
				<Form.Control
				  name="seudonimo"
				  type="text"
				  value={datoSeleccionado && datoSeleccionado.seudonimo}
				  onChange={handleChange}
				  pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
				  maxLength={20}
				/>
			  </FloatingLabel>
			</div>
		  <Row>
			<Col sm={6} className="mb-2">
			  <FloatingLabel label="Nombre">
				<Form.Control
				  name="nombre"
				  type="text"
				  value={datoSeleccionado && datoSeleccionado.nombre}
				  onChange={handleChange}
				  pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
				  maxLength={20}
				/>
			  </FloatingLabel>
			</Col>
			<Col sm={6} className="mb-2">
			  <FloatingLabel label="Apellido">
				<Form.Control
				  name="apellido"
				  type="text"
				  value={datoSeleccionado && datoSeleccionado.apellido}
				  onChange={handleChange}
				  pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
				  maxLength={20}
				/>
			  </FloatingLabel>
			</Col>
		  </Row>
		  <Form.Group className="mb-2">
			<FloatingLabel label="Teléfono">
			  <Form.Control
				name="telefono"
				type="text"
				value={datoSeleccionado && datoSeleccionado.telefono}
				onChange={handleChange}
				minLength={10}
				maxLength={10}
				pattern="[0-9]+$"
			  />
			</FloatingLabel>
		  </Form.Group>
		  <Form.Group className="mb-2">
			<FloatingLabel label="Correo electrónico">
			  <Form.Control
				name="email"
				type="email"
				value={datoSeleccionado && datoSeleccionado.email}
				onChange={handleChange}
			  />
			</FloatingLabel>
			</Form.Group>
			<Form.Group className="mb-2">
			<FloatingLabel label="Fecha naciminento">
			  <Form.Control
				name="fecha_nac"
				type="date"
				value={datoSeleccionado && datoSeleccionado.fecha_nac}
				onChange={handleChange}
			  />
			</FloatingLabel>
			</Form.Group>

		  <br />
		  <div className="mb-4">
			<Button type="submit" className="btn app-button shadow">
			  Guardar
			</Button>
		  </div>
		</Form>
	  </div>
	</div>;
	
};

export default Update;
