"use client";
import React from 'react';
import './PayLicencia.css';
import PropTypes from 'prop-types';

const PayLicencia = ({}) => {
	return (
		<div className='paylicencia'>
 			PayLicencia works!
 		</div>
	);
};

PayLicencia.propTypes = {};

export default PayLicencia;