import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import useAuth from "../../auth/useAuth";
import apiConfig from "../../services/apiConfig";
import Profile from "../profile/Profile";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import "./Navb.css";
import { NavLink } from "react-router-dom";
import CryptoJS from "crypto-js";

function Menu(props) {
  const abrir = props.abrir
  
  const auth = useAuth();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [show, setShow] = useState(false);


  function handleClose(){
    props.handleCloseMenu()
   
  };
  const handleShow = () => setShow(true);
useEffect(() => {
  setShow(abrir)
}, [abrir]);
  const actualizarDatos = (id) => {
    axios
      .get(`${apiConfig}profile/list/user.php?id=${id}`)
      .then((res) => {
        let { data } = res;
        setUser(data[0]);
      })
      .catch(function (res) {});
  };

  return (
    <>
            <Offcanvas show={show} placement={"start"} onHide={handleClose}>
              <Offcanvas.Header closeButton style={{background: '#736ced', color:'white'}}>
                <Profile actualizarDatos={actualizarDatos} user={user} />
              </Offcanvas.Header>
              <Offcanvas.Body>
                
                <div className="col-12">
                    <div className="mb-4 pt-2">
                      <NavLink exact  to={
                            "/profile/update/" +
                            encodeURIComponent(
                              CryptoJS.AES.encrypt(
                                JSON.stringify(user),
                                "clave_secreta"
                              ).toString()
                            )
                          } >
                        <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                        <span >Perfil</span><BsIcons.BsChevronRight />
                        </div>
                      
                        </NavLink>
                    </div>                
                    <div className="mb-4">
                    <NavLink exact to="/calendario" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                    <span style={{color: 'black'}}>Agenda</span><BsIcons.BsChevronRight />
                    </div>
                      </NavLink>
                    </div>
                    <div className="mb-4">
                    <NavLink exact to="/mensajeria" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                    <span style={{color: 'black'}}>Mensajería</span><BsIcons.BsChevronRight />
                    </div>
                      </NavLink>
                    </div>
                    <div className="mb-4">
                    <NavLink exact to="/reporte" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                      <span style={{color: 'black'}}>Reportes</span><BsIcons.BsChevronRight />
                      </div>
                      </NavLink>
                    </div>                 
                  <hr/>
                    <div className="mb-4">
                    <NavLink exact to="/config" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                      <span style={{color: 'black'}}>Parametros</span><BsIcons.BsChevronRight />
                      </div>
                      </NavLink>
                    </div>
                   </div>
                   <div className="mb-4">
                    <NavLink exact to="/calendario" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                      <span style={{color: 'black'}}>Suscripción</span><BsIcons.BsChevronRight />
                      </div>
                      </NavLink>
                    </div>
                    <hr/>
                    <div className="mb-4">
                    <NavLink exact to="/calendario" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                      <span style={{color: 'black'}}>Ayuda</span><BsIcons.BsChevronRight />
                      </div>
                      </NavLink>
                    </div>
                    <div className="mb-4">
                    <NavLink exact to="/calendario" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                      <span style={{color: 'black'}}>Acerca de Cronogram</span><BsIcons.BsChevronRight />
                      </div>
                      </NavLink>
                    </div>
                    <hr/>
                    <div className="mb-4">
                    <NavLink exact to="/terminos" >
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between',color: 'black', fontSize: '1.2rem',cursor: "pointer"}}>
                      <span style={{color: 'black'}}>Términos y condiciones</span><BsIcons.BsChevronRight />
                      </div>
                      </NavLink>
                    </div>
                  <div
                    className="fixed-footer text-center mb-2 pt-5"
                    style={{ color: "#736CED", cursor: "pointer" }}
                    onClick={auth.logoutUser}
                  >
                    Cerrar sesión
                  
                </div>
              </Offcanvas.Body>
            </Offcanvas>

    </>
  );
}
export default Menu;
