import React, { useState, useEffect } from 'react';
import * as BsIcons from "react-icons/bs";
import moment from "moment";
import "moment/locale/es";

const Calendario=({fecha, profesional, handleChangeFecha})=> {
      const [fechaSeleccionada, setFechaSeleccionada] = useState(fecha);
      const [mesActual, setMesActual] = useState(new Date().getMonth());
      const [añoActual, setAñoActual] = useState(new Date().getFullYear());
     
      const [porfesional,setProfesional] = useState(profesional);
      
      
      const obtenerFecha = (dia) => {
          let fec = moment(dia).format("YYYY-MM-DD");
          setFechaSeleccionada(dia);
          handleChangeFecha(fec)
         
      };
    
 



      useEffect(()=>{
        setProfesional(profesional)
      },[profesional])

     
    
 
        const generarCalendario = () => {
          const fechaActual = new Date();
    
          const primerDiaMes = new Date(añoActual, mesActual, 1);
          const ultimoDiaMes = new Date(añoActual, mesActual + 1, 0);
          const primerDiaSemana = primerDiaMes.getDay();
      
          const diasMesActual = ultimoDiaMes.getDate();
      
          const calendario = [];
      
          let dia = 1;
      
          for (let i = 0; i < 6; i++) {
              const semana = [];
              for (let j = 0; j < 7; j++) {
                  if ((i === 0 && j < primerDiaSemana) || dia > diasMesActual) {
                      semana.push(<td className="td" key={`${i}-${j}`}>&nbsp;</td>);
                  } else {
                      const fecha = new Date(añoActual, mesActual, dia);
                      const filfec = moment(fecha).format("YYYY-MM-DD");
                    
                      const esFechaPasada = moment(fecha).format("YYYY-MM-DD") < moment(fechaActual).format("YYYY-MM-DD");
      
                      const estiloDia = {
                          backgroundColor:
                              fechaSeleccionada &&
                              moment(fechaSeleccionada).format("YYYY-MM-DD") === filfec
                                  ? '#736ced'
                                  : esFechaPasada
                                  ? '#f0f0f0' // Color gris claro para fechas pasadas
                                  : '#dcdefc',
                          color: esFechaPasada ? '#999' : moment(fechaSeleccionada).format("YYYY-MM-DD") === filfec ?  '#fff' :'#736ced',
                          borderRadius: '10px',
                          border: 'solid 2px white',
                      };
      
                      semana.push(
                          <td
                              className={`td ${esFechaPasada ? 'inactive' : 'active'}`}
                              key={`${i}-${j}`}
                              onClick={() => !esFechaPasada && obtenerFecha(fecha)}
                              style={estiloDia}
                          >
                              <div>
                                  <div>{dia}</div>
                              </div>
                          </td>
                      );
      
                      dia++;
                  }
              }
              calendario.push(<tr key={i}>{semana}</tr>);
              if (dia > diasMesActual) break;
          }
      
          return calendario;
      };
      
    
    
      const cambiarMes = (mes) => {
        if (mes < 0) {
          setMesActual(11);
          setAñoActual(añoActual - 1);
        } else if (mes > 11) {
          setMesActual(0);
          setAñoActual(añoActual + 1);
        } else {
          setMesActual(mes);
        }
      };
    
      const cambiarAño = (año) => {
        setAñoActual(año);
      };
    
      const meses = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ];
// <button className="btn btn-primary btn-sm"  onClick={() => cambiarAño(añoActual - 1)}>Ant. Año</button>
//<button className="btn btn-primary btn-sm"  onClick={() => cambiarAño(añoActual + 1)}>Sig. Año</button>
  return (
    <div>     
      <div className="d-flex justify-content-between"> 
      <div className='botonturno'><BsIcons.BsChevronLeft onClick={() => cambiarMes(mesActual - 1)} style={{color:'#736ced'}}/></div>      
        
        <div><small>{meses[mesActual]} {añoActual}</small></div>
        <div className='botonturno'><BsIcons.BsChevronRight  onClick={() => cambiarMes(mesActual + 1)} style={{color:'#736ced'}}/></div> 
 
                
      </div>
      
      <div className="justify-content-center estilo-x">
      <table className='mx-auto'>
        <thead>
          <tr>
            <th className="cl">Dom</th>
            <th className="cl">Lun</th>
            <th className="cl">Mar</th>
            <th className="cl">Mié</th>
            <th className="cl">Jue</th>
            <th className="cl">Vie</th>
            <th className="cl">Sáb</th>
          </tr>
        </thead>
        <tbody>{generarCalendario()}</tbody>
      </table><br/>
      
      </div>
    </div>
  );
}
export default Calendario